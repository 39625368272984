import React, { FC } from 'react';
import { BackgroundColorVariant } from '@/enums/ColorMode';
import { TextSnippetTracked } from '@/components/shared/TextSnippet/TextSnippetTracked';
import { IPageData } from '@/interfaces/coreInformation';
import { BoxProps, styled } from '@/styled-system/jsx';
import { TextSnippetVariants, textSnippet } from '@/components/shared/TextSnippet/textSnippet.recipe';
import { siteName } from '@/constants/global';

interface ITextSnippetProps extends Omit<BoxProps, 'backgroundColor'> {
	id?: string;
	dataId: string;
	pageData?: IPageData;
	content?: string;
	parentBackgroundColor?: BackgroundColorVariant;
	preventAutomatedTracking?: boolean;
}

export const TextSnippet: FC<ITextSnippetProps> = ({
	id,
	dataId,
	pageData,
	content,
	parentBackgroundColor = BackgroundColorVariant.PRIMARY,
	preventAutomatedTracking,
	...rest
}) => {
	if (!content) {
		return null;
	}

	const className = textSnippet({ parentBackgroundColor, site: siteName as TextSnippetVariants['site'] });

	if (preventAutomatedTracking || !pageData) {
		return <styled.div id={id} data-id={dataId} className={className} dangerouslySetInnerHTML={{ __html: content }} />;
	}

	return (
		<TextSnippetTracked textBlockId={dataId} pageData={pageData}>
			<styled.div
				id={id}
				data-id={dataId}
				className={className}
				dangerouslySetInnerHTML={{ __html: content }}
				{...rest}
			/>
		</TextSnippetTracked>
	);
};
