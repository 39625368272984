/**
 * @packageDocumentation
 * @module tracking_utils
 */

import { ITrackedProps } from '@/components/shared/tracking/Tracked';
import { IBlockContent } from '@/interfaces/coreInformation';
import {
	IAttachedDOMTrackingEventInfo,
	ITrackedBlockInformation,
	ITrackingEventData,
	ITrackingHref,
	ITrackingInformation,
	ITrackingTarget,
	TrackingCategory,
} from '@/types/tracking';
import { logger } from '@/utils/logger';
import { getAutomaticLinkTrackingInformation, getUseCaseTrackingInformation } from '@/utils/tracking/automatedTracking';
import { TrackingUseCase } from '@/utils/tracking/usecaseTracking.config';
import { v4 as uuidv4 } from 'uuid';

function removeUndefinedObjectProps(object: { [key: string]: any }): void {
	// Object key can be undefined and we don't need it in the payload, also
	// object key can be "|" value since values from placeholder "{target-contentid}|{target-pagetitle}",
	// they can both be empty, in that case stale "|" needs to be removed
	// case is also added if there were three items in the future
	Object.keys(object).forEach((key) =>
		object[key] === undefined || object[key] === '|' || object[key] == '||' ? delete object[key] : {}
	);
}

function mergeInfoWithLeftPrioritization(
	primaryInformation: ITrackingInformation | undefined,
	secondaryInformation: ITrackingInformation | undefined
): ITrackingInformation {
	const trackingInformationBuild: ITrackingInformation = {
		action: undefined,
		assetType: undefined,
		assetValue: undefined,
		category: undefined,
		conversionId: undefined,
		label: undefined,
		mediaSource: undefined,
		mediaType: undefined,
		mediaValue: undefined,
		nonInteraction: undefined,
		techCategory: undefined,
		targetUrl: undefined,
		value: undefined,
	};

	for (const propertyName in trackingInformationBuild) {
		const trackingPropName: keyof typeof primaryInformation = propertyName as keyof typeof primaryInformation;

		const primaryProp = primaryInformation ? primaryInformation[trackingPropName] : undefined;
		const secondaryProp = secondaryInformation ? secondaryInformation[trackingPropName] : undefined;

		if (primaryProp !== undefined) {
			trackingInformationBuild[trackingPropName] = primaryProp;
		} else if (secondaryProp !== undefined) {
			trackingInformationBuild[trackingPropName] = secondaryProp;
		}
	}
	removeUndefinedObjectProps(trackingInformationBuild);

	return trackingInformationBuild;
}

/**
 * Merges the given Tracking information in the following order:
 *
 * trackingInformation > component > usecase >  href
 *
 * Meaning that if any attribute is not specified the tracking attribute will default to the latter.
 * @param trackingInformationOverride The tracking information that should be sent.
 * @param componentTrackingInformation The component of the tracking configuration.
 * @param useCase The tracking use case can be set to select the use case depending on the relating tracking config.
 * @param href The url from which the automatic tracking information should be generated.
 * @param trackingTarget The TrackingTarget holds information about the targeted page / document when clicking a link. This is applied when using href and usecase tracking.
 * @param trackedBlockInformation CMS tracking information of tracked block
 * @throws Error, if the merging could not be completed. Further information can be found in the logs.
 */
const mergeTrackingInformation = (
	trackingInformationOverride?: ITrackingInformation | undefined,
	componentTrackingInformation?: ITrackingInformation | undefined,
	useCase?: TrackingUseCase | undefined,
	href?: ITrackingHref,
	trackingTarget?: ITrackingTarget | undefined,
	trackedBlockInformation?: Partial<ITrackedBlockInformation> | undefined
): ITrackingInformation => {
	let trackingInformation: ITrackingInformation = mergeInfoWithLeftPrioritization(
		trackingInformationOverride,
		componentTrackingInformation
	);

	const automatedTrackingInfo =
		href === undefined
			? undefined
			: getAutomaticLinkTrackingInformation(href, trackingTarget, trackingInformation, trackedBlockInformation);

	const useCaseTrackingInfo =
		useCase === undefined || trackingTarget === undefined
			? undefined
			: getUseCaseTrackingInformation(useCase, trackingTarget);

	trackingInformation = mergeInfoWithLeftPrioritization(trackingInformation, useCaseTrackingInfo);
	trackingInformation = mergeInfoWithLeftPrioritization(trackingInformation, automatedTrackingInfo);

	return trackingInformation;
};

enum TealiumTrackingEventNames {
	Component = 'component-tracking',
	Disclaimer = 'disclaimer-tracking',
}

/**
 * Static js function to send the tracking Event.
 *
 * Behaves the same as the doTrack method but allows for passing some additional trackingInformation. In some cases
 * there might be multiple tracking events attached to one component, so they may be passed here.
 *
 * The generation of the tracked block information is not affected.
 *
 * @param originalEvent The original DOM event that triggered this tracking event.
 * @param customTrackingInformation Tracking information to send. While merging, this information gets prioritized over all other.
 * @param skipMerge When set to true, all other tracking Information will be ignored and no merge occurs. TrackedBlockInformation will still be appended normally.
 */
const doTrack = (
	originalEvent: (Event & { target: HTMLElement }) | CustomTrackingEvent,
	customTrackingInformation: ITrackingInformation = {},
	skipMerge = false
): void => {
	let trackingElement: HTMLElement | undefined;

	let currentTarget = getTrackingElement(originalEvent);

	if (!currentTarget) {
		logger.debug('Tracking Event could not be fired since no tracking Element could be found.');

		return;
	}

	while (!trackingElement) {
		trackingElement = currentTarget.dataset?.tracked == 'true' ? currentTarget : undefined;

		if (trackingElement) {
			break;
		}

		if (!currentTarget.parentElement) {
			break;
		}
		currentTarget = currentTarget.parentElement;
	}

	if (!trackingElement) {
		console.error('Tried tracking Event but no Tracked component could be found.');

		return;
	}

	const event = new CustomEvent<IAttachedDOMTrackingEventInfo>('tracking-on' + originalEvent.type, {
		detail: {
			originalEvent,
			customTrackingInformation,
			skipMerge,
		},
	});

	trackingElement?.dispatchEvent(event);
};

export interface CustomTrackingEvent extends CustomEvent<{ target: HTMLElement }> {}

export const CustomTrackingEvent = {
	FromId: (trackingId: string, eventType = 'click') => {
		return createOriginalTrackingEventFromId(trackingId, eventType);
	},
	FromTarget: (trackingTarget: HTMLElement, eventType = 'click') => {
		return createOriginalTrackingEvent(trackingTarget, eventType);
	},
};

/**
 * Sometimes no direct Event object is available (e.g. when custom changeHandlers are used). This method
 * @param trackingElement The Element that should be passed for generating tracking information
 * @param eventType The event type that should be associated with the Event.
 * @returns
 */
const createOriginalTrackingEvent = (trackingElement: HTMLElement, eventType = 'click'): CustomTrackingEvent => {
	return new CustomEvent(eventType, {
		detail: {
			target: trackingElement,
		},
	});
};

const createOriginalTrackingEventFromId = (trackingId: string, eventType = 'click'): CustomTrackingEvent => {
	const trackingTarget = document.querySelector(`[data-trackingid="${trackingId}"]`);

	if (!trackingTarget) {
		logger.debug(
			`Tried createing a custom original tracking event, but no DOM element was found for tracking ID ${trackingId}`
		);
	}

	return createOriginalTrackingEvent(trackingTarget as HTMLElement, eventType);
};

/**
 * INFO: This sends the event directly to Tealium. In most cases you would want to pick the doTrack function instead, since they take care of a lot of internal logic.
 *
 * Sends the tracking Event to the tealium instance.
 *
 * This also takes care of some formatting issues since the naming that the utag instance needs is different.
 * @param trackingEventData The data sent to the tealium instance
 * @param target The Target to which the event should be attached. Normally this should be the element where the event was triggered (eg. the button). If left empty the event will be dispatched on the document. In some cases that may be required.
 * @param originalEvent The original event is also sent to Tealium and some information is used. In most cases this would be a ClickEvent but of course other DOM Events may trigger tracking.
 */
const sendTealiumTrackingEvent = (
	trackingEventData: ITrackingEventData,
	target?: HTMLElement | null | undefined,
	originalEvent?: Event
): void => {
	// Disclaimer are tracked differently than components
	let trackingEventName = TealiumTrackingEventNames.Component;

	if (trackingEventData.event.category === TrackingCategory.Disclaimer) {
		trackingEventName = TealiumTrackingEventNames.Disclaimer;
	}

	const trackingEvent = new CustomEvent(trackingEventName, {
		bubbles: true,
		cancelable: false,
		detail: {
			trackingInfo: convertToTealiumFriendlyEventData(trackingEventData),
			originalEvent: originalEvent,
		},
	});

	if (!target) {
		document.dispatchEvent(trackingEvent);
	}
	target?.dispatchEvent(trackingEvent);
};

const convertToTealiumFriendlyEventData = (eventData: ITrackingEventData) => {
	const levels = eventData.levels.map((ci) => convertToTealiumFriendlyComponent(ci));
	const component = eventData.component ? convertToTealiumFriendlyComponent(eventData.component) : {};
	const nestingLevel = eventData.nestingLevel;
	const event = convertToTealiumFriendlyInformation(eventData.event);

	return {
		levels,
		nestingLevel,
		component,
		event,
	};
};

const getTrackingElement = (originalEvent: Event): HTMLElement => {
	return (
		(originalEvent.target as HTMLElement) ?? (originalEvent as CustomEvent<{ target: HTMLElement }>).detail?.target
	);
};

/**
 * Adjusts the naming convention to match the expected property names for tealium and removes non-related information
 * if the given blockInfo has any.
 */
const convertToTealiumFriendlyComponent = (blockInfo: ITrackedBlockInformation) => ({
	component_id: blockInfo.blockId,
	component_name: blockInfo.blockName,
	component_instance_id: blockInfo.blockInstanceId,
	component_instance_name: blockInfo.blockInstanceName,
	component_version: blockInfo.blockVersion,
	...(blockInfo.fallbackCountry && { fallback_country: blockInfo.fallbackCountry }),
	...(blockInfo.fallbackLanguage && { fallback_language: blockInfo.fallbackLanguage }),
});

/**
 * Adjusts the naming convention to match the expected property names for tealium and removes non-related information
 * if the given info has any.
 */
const convertToTealiumFriendlyInformation = (info: ITrackingInformation) => {
	const eventId = getTealiumEventId();

	const converted: { [key: string]: boolean | string | undefined } = {
		nonInteraction: info.nonInteraction,
		action: info.action,
		category: info.category,
		tech_category: info.techCategory,
		value: info.value,
		label: info.label,
		conversionId: info.conversionId,
		asset_value: info.assetValue,
		asset_type: info.assetType,
		media_value: info.mediaValue,
		media_type: info.mediaType,
		media_source: info.mediaSource,
		targeturl: info.targetUrl,
		...(eventId && { event_id: eventId }),
	};

	// Remove undefined keys
	Object.keys(converted).forEach((key) => (converted[key] === undefined ? delete converted[key] : {}));

	return converted;
};

/**
 * Expects the blockDefinition received from the content delivery API and returns the correct properties for the Tracked component
 * that can be generated from the CMS.
 * @param blockDefinition The json formatted object received from the content-delivery API.
 * @returns ITrackedProps to provide the Tracked component with.
 */
const getTrackingPropertiesFromCMSBlock = (blockDefinition: IBlockContent): Partial<ITrackedProps> => {
	if (!blockDefinition) {
		return {};
	}

	const trackedBlockInformation: ITrackedBlockInformation = getTrackedBlockInformation(blockDefinition);

	const cmsTrackingInformation = getCMSTrackingInformation(blockDefinition);

	return { trackedBlockInformation, cmsTrackingInformation };
};

export const getTrackedBlockInformation = (blockDefinition: IBlockContent): ITrackedBlockInformation => {
	if (!blockDefinition) {
		return {
			blockId: '',
			blockInstanceId: '',
			blockName: '',
			blockInstanceName: '',
			blockIsRelevant: false,
			blockVersion: '',
		};
	}

	const languageAndCountry = blockDefinition.masterLanguage?.name.split('-');
	const fallbackLanguage = languageAndCountry?.[0];
	const fallbackCountry = languageAndCountry?.length > 1 ? languageAndCountry[1]?.toLowerCase() : '';

	return {
		fallbackLanguage,
		fallbackCountry,
		blockId: blockDefinition.contentType.at(-1) ?? '',
		blockInstanceId: blockDefinition.contentLink.id.toString(),
		blockName: blockDefinition.contentType.at(-1) ?? '',
		blockInstanceName: blockDefinition.name,
		blockIsRelevant: blockDefinition.isRelevantTrackingComponent ?? true,
		blockVersion: blockDefinition.contentLink.workId.toString(),
	};
};

export const getCMSTrackingInformation = (blockDefinition: IBlockContent): ITrackingInformation => {
	return {
		techCategory: blockDefinition.eventTechCategory,
		category: blockDefinition.eventCategory,
		action: blockDefinition.eventAction,
		label: blockDefinition.eventLabel,
		value: blockDefinition.eventValue,
		conversionId: blockDefinition.conversionId,
		mediaSource: blockDefinition.mediaSource,
		mediaType: blockDefinition.mediaType,
		mediaValue: blockDefinition.mediaValue,
		assetType: blockDefinition.assetType,
		assetValue: blockDefinition.assetValue,
	};
};

const generateTrackingId = (): string => {
	return uuidv4();
};

const getTealiumEventId = (): string | undefined => {
	if (typeof utag === 'undefined') {
		return;
	}

	if (typeof utag?.ext?.createUniqueEventId === 'undefined') {
		return;
	}

	try {
		return utag?.ext?.createUniqueEventId();
	} catch {
		return;
	}
};

const getTrackingHref = (url: string, domain?: string): ITrackingHref => {
	return {
		originalHref: url,
		absoluteHref:
			url && domain && new RegExp(/^((\/)|(\.\/)|(\.\.\/))(.*)/i).test(url) ? domain + url.replace(/^(\.)+/, '') : url,
	};
};

export {
	doTrack,
	generateTrackingId,
	getTrackingHref,
	getTrackingPropertiesFromCMSBlock,
	mergeTrackingInformation,
	sendTealiumTrackingEvent,
};
