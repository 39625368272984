export enum Icon {
	_3D_PRINTER = '_3D_PRINTER',
	_5G = '_5G',
	ADDITIONAL_DOCUMENT = 'ADDITIONAL_DOCUMENT',
	AEROSPACE_DEFENCE = 'AEROSPACE_DEFENCE',
	AGRICULTURE = 'AGRICULTURE',
	AIRLINES = 'AIRLINES',
	ALERT = 'ALERT',
	ALPHA_BASIS = 'ALPHA_BASIS',
	ALPHA_PYRAMIDE = 'ALPHA_PYRAMIDE',
	ALU = 'ALU',
	ARCHIVE = 'ARCHIVE',
	ARROW_RIGHT = 'ARROW_RIGHT',
	ASSET_ALLOCATION = 'ASSET_ALLOCATION',
	AUDIO = 'AUDIO',
	AUDIO_MUTE = 'AUDIO_MUTE',
	AUTOMOBILES = 'AUTOMOBILES',
	AWARDS = 'AWARDS',
	BACKWARD = 'BACKWARD',
	BAG = 'BAG',
	BALANCE = 'BALANCE',
	BATTERY_FULL = 'BATTERY_FULL',
	BATTERY_LOW = 'BATTERY_LOW',
	BATTERY_MEDIUM = 'BATTERY_MEDIUM',
	BEAR = 'BEAR',
	BELL = 'BELL',
	BEVERAGES = 'BEVERAGES',
	BIOTECHNOLOGY = 'BIOTECHNOLOGY',
	BONDS = 'BONDS',
	BOOK_MAGAZINE = 'BOOK_MAGAZINE',
	BOOKMARK = 'BOOKMARK',
	BREAD = 'BREAD',
	BULL = 'BULL',
	BUSINESS_IN_GENERAL = 'BUSINESS_IN_GENERAL',
	CACAO = 'CACAO',
	CALCULATOR = 'CALCULATOR',
	CALENDAR = 'CALENDAR',
	CAMERA = 'CAMERA',
	SURVEILLANCE = 'SURVEILLANCE',
	CARDS = 'CARDS',
	CASH_SEK = 'CASH_SEK',
	CERTIFICATE = 'CERTIFICATE',
	CLARITY_INNOVATION = 'CLARITY_INNOVATION',
	CLEANING_TOOLS = 'CLEANING_TOOLS',
	CLEAR = 'CLEAR',
	CLIENT_EXPERIENCE = 'CLIENT_EXPERIENCE',
	CLIENT_MAN = 'CLIENT_MAN',
	CLIENT_ONBOARDING = 'CLIENT_ONBOARDING',
	CLIENT_WOMAN = 'CLIENT_WOMAN',
	CLIMATE_CHANGE = 'CLIMATE_CHANGE',
	CLOSE = 'CLOSE',
	CLOUD = 'CLOUD',
	COAL = 'COAL',
	COFFEE = 'COFFEE',
	COMMODITIES = 'COMMODITIES',
	COMPANY = 'COMPANY',
	COMPASS = 'COMPASS',
	COMPUTER = 'COMPUTER',
	CONNECTION = 'CONNECTION',
	CONSTRUCTION = 'CONSTRUCTION',
	CONSUMER_DISCRETIONARY = 'CONSUMER_DISCRETIONARY',
	CONSUMER_STAPLES = 'CONSUMER_STAPLES',
	CONTACT = 'CONTACT',
	CONTRACT = 'CONTRACT',
	CONVEYER_BELT = 'CONVEYER_BELT',
	COPY = 'COPY',
	CURRENCY = 'CURRENCY',
	DENTAL = 'DENTAL',
	DEPOT = 'DEPOT',
	DESINFECTION = 'DESINFECTION',
	DIALOG = 'DIALOG',
	DIALOG_SIMPLE = 'DIALOG_SIMPLE',
	DISCLAIMER = 'DISCLAIMER',
	DISHES = 'DISHES',
	DOCUMENT = 'DOCUMENT',
	DOCUMENT_ALERT = 'DOCUMENT_ALERT',
	DOCUMENT_AVI = 'DOCUMENT_AVI',
	DOCUMENT_DOC = 'DOCUMENT_DOC',
	DOCUMENT_PDF = 'DOCUMENT_PDF',
	DOCUMENT_PPT = 'DOCUMENT_PPT',
	DOCUMENT_TXT = 'DOCUMENT_TXT',
	DOCUMENT_XLS = 'DOCUMENT_XLS',
	DOCUMENT_ZIP = 'DOCUMENT_ZIP',
	DONT_TALK = 'DONT_TALK',
	DOWN = 'DOWN',
	DOWNLOAD = 'DOWNLOAD',
	ELECTRICAL_EQUIPMENT = 'ELECTRICAL_EQUIPMENT',
	ELEVATOR = 'ELEVATOR',
	EMAIL = 'EMAIL',
	EMAIL_OPEN = 'EMAIL_OPEN',
	EMERGING_MARKETS = 'EMERGING_MARKETS',
	ENERGY = 'ENERGY',
	ENVIRONMENTAL = 'ENVIRONMENTAL',
	EQUITIES = 'EQUITIES',
	ESCOOTER = 'ESCOOTER',
	ESPORTS = 'ESPORTS',
	EUROPEAN = 'EUROPEAN',
	EXCHANGE = 'EXCHANGE',
	EXPAND = 'EXPAND',
	EXTERNAL_LINK = 'EXTERNAL_LINK',
	FACEBOOK = 'FACEBOOK',
	FAMILY = 'FAMILY',
	FILTER = 'FILTER',
	FIRE = 'FIRE',
	FOOD_PRODUCTS = 'FOOD_PRODUCTS',
	FORWARD = 'FORWARD',
	FUEL = 'FUEL',
	FULLSCREEN = 'FULLSCREEN',
	GIFT = 'GIFT',
	GLASS = 'GLASS',
	GLOBAL_BRANDS = 'GLOBAL_BRANDS',
	GOLD = 'GOLD',
	GONDOLA = 'GONDOLA',
	GREETING_CARDS = 'GREETING_CARDS',
	HANDICAP = 'HANDICAP',
	HARDWARE = 'HARDWARE',
	HEALTH_CARE = 'HEALTH_CARE',
	HEARING = 'HEARING',
	HEATING = 'HEATING',
	HELP = 'HELP',
	HIERARCHY = 'HIERARCHY',
	HIGH_YIELD_BONDS = 'HIGH_YIELD_BONDS',
	HOME = 'HOME',
	HOTELS = 'HOTELS',
	HOUSEHOLD_PRODUCTS = 'HOUSEHOLD_PRODUCTS',
	INDUSTRIALS = 'INDUSTRIALS',
	INFINITY = 'INFINITY',
	INFLATION_LINKED_BONDS = 'INFLATION_LINKED_BONDS',
	INFORMATION = 'INFORMATION',
	INJECTION = 'INJECTION',
	INSTAGRAM = 'INSTAGRAM',
	IT = 'IT',
	JOB_CV = 'JOB_CV',
	KEYACCESS = 'KEYACCESS',
	KID_DE = 'KID_DE',
	KID_EN = 'KID_EN',
	KID_FR = 'KID_FR',
	KID_IT = 'KID_IT',
	LAPTOP = 'LAPTOP',
	LEAF = 'LEAF',
	LIKE = 'LIKE',
	LINKEDIN = 'LINKEDIN',
	LIVESTREAM = 'LIVESTREAM',
	LIVESTREAM_INTERRUPTED = 'LIVESTREAM_INTERRUPTED',
	LOCATION = 'LOCATION',
	LOGIN = 'LOGIN',
	LOGOUT = 'LOGOUT',
	MANAGER = 'MANAGER',
	MARINE = 'MARINE',
	MATERIAL_MIXED = 'MATERIAL_MIXED',
	MEDICINE = 'MEDICINE',
	MENU = 'MENU',
	METALS_MINING = 'METALS_MINING',
	MINUS = 'MINUS',
	MOBILE = 'MOBILE',
	MONEY = 'MONEY',
	MOON = 'MOON',
	MORE = 'MORE',
	MORE_DOTS = 'MORE_DOTS',
	MOTORCYCLE_VESPA = 'MOTORCYCLE_VESPA',
	MOVIE = 'MOVIE',
	MULTIPOLAR = 'MULTIPOLAR',
	NESPRESSO = 'NESPRESSO',
	NEWS = 'NEWS',
	NO_ENTRY = 'NO_ENTRY',
	NO_SMOKE = 'NO_SMOKE',
	NOT_SECURE = 'NOT_SECURE',
	OBJECTIVE_ADVICE = 'OBJECTIVE_ADVICE',
	OBSERVE = 'OBSERVE',
	OIL = 'OIL',
	OIL_PIPE = 'OIL_PIPE',
	OK = 'OK',
	PAPER_TOWELS = 'PAPER_TOWELS',
	PAUSE = 'PAUSE',
	PEN = 'PEN',
	PERCENT = 'PERCENT',
	PERSON = 'PERSON',
	PERSON_FEMAL = 'PERSON_FEMAL',
	PERSONALIZE = 'PERSONALIZE',
	PET = 'PET',
	PILLS = 'PILLS',
	PLANT = 'PLANT',
	PLAY = 'PLAY',
	PLUS = 'PLUS',
	PODCAST = 'PODCAST',
	PORTFOLIO = 'PORTFOLIO',
	PRECAUTION = 'PRECAUTION',
	PRINT = 'PRINT',
	PRINTER_ROOM = 'PRINTER_ROOM',
	PROCESS = 'PROCESS',
	PROCESS_FULL = 'PROCESS_FULL',
	PROCESS_HALF = 'PROCESS_HALF',
	PROCESS_QUARTER = 'PROCESS_QUARTER',
	PROCESS_THREEQUARTER = 'PROCESS_THREEQUARTER',
	PROCESS_ZERO = 'PROCESS_ZERO',
	PROFESSIONAL_SERVICES = 'PROFESSIONAL_SERVICES',
	PUZZLE = 'PUZZLE',
	QRCODE = 'QRCODE',
	RATING = 'RATING',
	RECYCLE = 'RECYCLE',
	RECYCLING = 'RECYCLING',
	RESEARCH = 'RESEARCH',
	RESTAURANT = 'RESTAURANT',
	RM_GUIDEANCE = 'RM_GUIDEANCE',
	ROAD_RAIL = 'ROAD_RAIL',
	ROBOTIC = 'ROBOTIC',
	ROCKET = 'ROCKET',
	RSS_FEED = 'RSS_FEED',
	RUBBISH = 'RUBBISH',
	SAVING = 'SAVING',
	SCREW = 'SCREW',
	SEARCH = 'SEARCH',
	SECURITY = 'SECURITY',
	SELECTION_CHOICE = 'SELECTION_CHOICE',
	SEND = 'SEND',
	SERVER = 'SERVER',
	SETTINGS = 'SETTINGS',
	SEVERAL_PERSONS = 'SEVERAL_PERSONS',
	SHARE = 'SHARE',
	SHOP = 'SHOP',
	SHOWER_MEN = 'SHOWER_MEN',
	SHOWER_WOMEN = 'SHOWER_WOMEN',
	SIMILARITY = 'SIMILARITY',
	SMALL_LARGE_CAPS = 'SMALL_LARGE_CAPS',
	SMART_FARMING = 'SMART_FARMING',
	SMARTWATCH = 'SMARTWATCH',
	SMILEY = 'SMILEY',
	SMILEY_SAD = 'SMILEY_SAD',
	SMILEY_SPEECHLESS = 'SMILEY_SPEECHLESS',
	SMOKE = 'SMOKE',
	SNOW = 'SNOW',
	SOAP = 'SOAP',
	SOFTWARE = 'SOFTWARE',
	SPEECH_BUBBLE = 'SPEECH_BUBBLE',
	SPOTLIGHT = 'SPOTLIGHT',
	STAR = 'STAR',
	STATUS_0 = 'STATUS_0',
	STATUS_100 = 'STATUS_100',
	STATUS_25 = 'STATUS_25',
	STATUS_50 = 'STATUS_50',
	STATUS_75 = 'STATUS_75',
	STOP = 'STOP',
	STRATEGY = 'STRATEGY',
	SUN = 'SUN',
	SUPPORT = 'SUPPORT',
	SWISS = 'SWISS',
	SWISS_EQUITIES = 'SWISS_EQUITIES',
	TABLET = 'TABLET',
	TECHNOLOGY = 'TECHNOLOGY',
	TELECOMMUNICATION = 'TELECOMMUNICATION',
	TERMSHEET_DE = 'TERMSHEET_DE',
	TERMSHEET_EN = 'TERMSHEET_EN',
	TERMSHEET_FR = 'TERMSHEET_FR',
	TERMSHEET_IT = 'TERMSHEET_IT',
	THUMB_DOWN = 'THUMB_DOWN',
	THUMB_UP = 'THUMB_UP',
	TIKTOK = 'TIKTOK',
	TIME = 'TIME',
	TOILET = 'TOILET',
	TOOLS = 'TOOLS',
	TOUCH_ID = 'TOUCH_ID',
	TRANSACTION = 'TRANSACTION',
	TRANSPARENCY = 'TRANSPARENCY',
	TRANSPORTATION = 'TRANSPORTATION',
	TRASH = 'TRASH',
	TREE = 'TREE',
	TRENDS = 'TRENDS',
	TRUCK = 'TRUCK',
	TWITTER = 'TWITTER',
	UP = 'UP',
	UPLOAD = 'UPLOAD',
	UTILITY = 'UTILITY',
	VACATION = 'VACATION',
	VALUE = 'VALUE',
	VALVE = 'VALVE',
	VENTILATION = 'VENTILATION',
	VIMEO = 'VIMEO',
	VIRUS = 'VIRUS',
	VOLUME_DOWN = 'VOLUME_DOWN',
	VOLUME_UP = 'VOLUME_UP',
	VONTOBEL_INVESTMENTGRADEBONDS_RGB_SAND = 'VONTOBEL_INVESTMENTGRADEBONDS_RGB_SAND',
	VONTOBEL_WIFI_RGB_CITRINE = 'VONTOBEL_WIFI_RGB_CITRINE',
	VRGLASSES = 'VRGLASSES',
	WALLET = 'WALLET',
	WARDROBE = 'WARDROBE',
	WATCH = 'WATCH',
	WATER = 'WATER',
	WATER_WAVE = 'WATER_WAVE',
	WC_MEN = 'WC_MEN',
	WEIGHT = 'WEIGHT',
	WIND = 'WIND',
	WINDMILL = 'WINDMILL',
	XING = 'XING',
	YOUTUBE = 'YOUTUBE',
}

export enum IconPosition {
	LEFT = 'Left',
	RIGHT = 'Right',
	LEFT_AND_RIGHT = 'LeftAndRight',
}
