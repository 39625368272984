import { cmsHost } from '@/constants/global';
import { CountryCode } from '@/enums/Language';
import { IContent, IPageLanguage } from '@/interfaces/coreInformation';
import { fetcher } from '@/utils/fetcher';
import { logger } from '@/utils/logger';
import { uniq } from 'lodash';

export interface ILanguageSettings extends Partial<IContent> {
	availableSiteCultures: Record<string, Array<string>>;
	mappingsJson: Record<string, string>;
}

export async function fetchLanguageSettings(): Promise<ILanguageSettings> {
	const url = cmsHost + '/api/settings/GetLanguagesSettings';

	const result = await fetcher(url, {
		next: {
			revalidate: 24 * 60 * 60, // 24 hours
		},
	});

	if (!result.ok) {
		console.error('Error fetching language settings. Status code: ', result.status);

		return {} as ILanguageSettings;
	}

	const settings = await result.json();

	try {
		settings.availableSiteCultures = JSON.parse(settings.availableSiteCultures.toLowerCase());
		settings.mappingsJson = JSON.parse(settings.mappingsJson);
	} catch {
		logger.error('Error parsing language settings! ' + url);
	}

	return (settings as ILanguageSettings) ?? {};
}

export const splitLocale = (locale: string) => {
	const [language, country] = locale?.split('-');

	return { language, country: country?.toLowerCase() || 'global' };
};

export const getUpdatedLocale = (language?: string, country?: string) => {
	const pathArray = window?.location?.pathname.split('/');

	const [currentLanguage, currentCountry = ''] = pathArray[1].split('-');

	let newCulture;

	if ((language === CountryCode.EN || language === CountryCode.ES) && (!country || country === CountryCode.GLOBAL)) {
		newCulture = language;
	} else {
		newCulture = `${language || currentLanguage}-${country || currentCountry}`;
	}

	pathArray[1] = newCulture;

	return { culture: newCulture, path: pathArray.join('/') };
};

export const extractAvailableLanguages = (cultures: Array<IPageLanguage>, filterCountry: string) => {
	const filteredLanguages = cultures.reduce((acc, iter) => {
		const [language, country] = iter.name.split('-');

		if (!country) {
			if (filterCountry === CountryCode.GLOBAL) {
				return [...acc, language];
			} else {
				return acc;
			}
		}

		if (country.toUpperCase() === filterCountry.toUpperCase()) {
			return [...acc, language];
		}

		return acc;
	}, [] as Array<string>);

	return uniq(filteredLanguages);
};

export const compareCountries = (c1: string, c2: string) => {
	return c1?.toUpperCase() === c2?.toUpperCase();
};

export const checkCultureExistance = (languages: Array<IPageLanguage>, language: string, country?: string) => {
	return languages.some((item: IPageLanguage) => {
		if (item.name === language && !country) {
			// for /en
			return true;
		}

		return item.name.toLowerCase() === `${language.toLowerCase()}-${country?.toLowerCase()}`;
	});
};

const cultureRegex = /^[a-z]{2}-[a-z]{2}$/;

export const checkCultureValidity = (languageSettings: ILanguageSettings, rawCulture: string) => {
	const culture = rawCulture.toLowerCase();

	if (languageSettings?.availableSiteCultures?.global?.includes(culture)) {
		return true;
	}

	if (cultureRegex.test(culture)) {
		const [language, country] = culture.split('-');

		return !!languageSettings?.availableSiteCultures?.[country]?.includes(language);
	}

	logger.debug('Recieved culture not valid:', culture);

	return false;
};
