'use client';

import { Button } from '@/components/core/Button/Button';
import { Checkbox } from '@/components/core/Checkbox';
import { SelectIcon } from '@/components/core/Select';
import { Text } from '@/components/core/Text/Text';
import { WarningIcon } from '@/icons/WarningIcon';
import { ISelectionItemResponse } from '@/interfaces/blocks/forms';
import { css } from '@/styled-system/css';
import { Box, styled } from '@/styled-system/jsx';
import { dropdown } from '@/styles/theme/dropdown';
import { useOutsideClick } from '@/utils/handleOutsideClick';
import { ChangeEvent, InputHTMLAttributes, RefObject, useRef, useState, forwardRef } from 'react';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';
import { FieldError } from 'react-hook-form';
import { Tags } from '@/components/core/Tags/Tags';

interface IDropdownProps extends InputHTMLAttributes<HTMLInputElement> {
	selectionItems: Array<ISelectionItemResponse>;
	placeholder?: string;
	label?: string;
	helperText?: string;
	disabled?: boolean;
	readOnly?: boolean;
	multiple?: boolean;
	error?: FieldError;
	isError?: boolean;
	defaultValue: string;
	trackingRef: RefObject<HTMLDivElement>;
	translations: TranslationLabelValues;
}

export const FormDropdown = forwardRef<HTMLButtonElement, IDropdownProps>(
	(
		{
			selectionItems,
			placeholder,
			label,
			helperText: _helperText,
			disabled = false,
			readOnly = false,
			multiple = false,
			name,
			required,
			hidden,
			defaultValue,
			onChange,
			isError,
			error,
			trackingRef,
			translations,
		},
		ref
	) => {
		const wrapperRef = useRef<HTMLDivElement>(null);
		const [isOpen, setIsOpen] = useState(false);
		const defaultValueArray = typeof defaultValue === 'string' && defaultValue !== '' ? defaultValue.split(',') : [];

		useOutsideClick(wrapperRef, () => setIsOpen(false));

		const handleOnChange = (itemValue: string) => {
			if (!multiple) {
				onChange && onChange({ target: { value: itemValue } } as ChangeEvent<HTMLInputElement>);
			} else {
				const valueExists = defaultValueArray?.includes(itemValue);

				if (valueExists) {
					const filteredValueArray = defaultValueArray?.filter((item) => item !== itemValue);

					onChange && onChange({ target: { value: filteredValueArray?.join(',') } } as ChangeEvent<HTMLInputElement>);
				} else {
					const filteredValueArray = [...(defaultValueArray ?? []), itemValue];

					onChange && onChange({ target: { value: filteredValueArray?.join(',') } } as ChangeEvent<HTMLInputElement>);
				}
			}

			if (!multiple) {
				return setIsOpen(false);
			}
		};

		const classes = dropdown.raw({ disabled, readOnly, multiple, isError, hidden });
		const optionalLabel = translations ? ` (${translate(translations, 'form.optional', 'optional')})` : ` (optional)`;

		let dropdownItems = selectionItems
			?.filter((item) => item?.contentLink?.expanded?.value)
			?.map((item) => ({
				value: item?.contentLink?.expanded?.value,
				hidden: item?.contentLink?.expanded?.isHidden,
				key: item?.contentLink?.id,
				label: item?.contentLink?.expanded?.textOrLabel,
			}));

		if (!multiple && !required) {
			dropdownItems = [{ value: '', hidden: false, key: 0, label: placeholder ?? '' }, ...dropdownItems];
		}

		const selectedDropdownItems = dropdownItems.filter((item) => defaultValueArray.indexOf(item.value) >= 0);

		return (
			<Box className={css(classes.root)} ref={trackingRef}>
				{label && (
					<Text className={css(classes.label)} size="xs" fontWeight={600}>
						{`${label} ${required ? ' *' : optionalLabel}`}
					</Text>
				)}
				<styled.select id={name} name={name} required={required} multiple={false} height={0} display="none">
					{dropdownItems?.map(({ label, ...props }) => (
						<option {...props} key={props.value}>
							{label}
						</option>
					))}
				</styled.select>

				<Box className={css(classes.select)} ref={wrapperRef}>
					<button
						ref={ref}
						className={css(classes.trigger)}
						onClick={(e) => {
							e.preventDefault();
							setIsOpen(!isOpen);
						}}
						data-open={isOpen}
						data-selected={selectedDropdownItems.length > 0}
					>
						{defaultValueArray.length ? (
							<>
								{multiple ? (
									<Tags.Root>
										{selectedDropdownItems?.map((item) => {
											return (
												<Tags.Item key={item.key} className={css(classes.tag)}>
													<Tags.Label>{item.label}</Tags.Label>
													<Tags.CloseButton
														as={Box}
														onClick={(e: MouseEvent) => {
															e.stopPropagation();
															e.preventDefault();

															handleOnChange(item.value);
														}}
													/>
												</Tags.Item>
											);
										})}
									</Tags.Root>
								) : (
									<Text size="md">{dropdownItems.find((item) => item.value === defaultValueArray[0])?.label}</Text>
								)}
							</>
						) : (
							<Text size="md">{placeholder}</Text>
						)}

						<SelectIcon isOpen={isOpen} className={css(classes.icon)} />
					</button>
					{isOpen && (
						<Box className={css(classes.positioner)} data-open={isOpen}>
							<styled.div
								className={css(classes.content, css.raw({ maxH: { base: 'xs', lg: 'lg' }, overflowY: 'auto' }))}
								id={label}
							>
								{dropdownItems
									.filter((item) => !item.hidden)
									?.map((item) =>
										multiple ? (
											<Button
												rootProps={classes.option}
												variant="unstyled"
												onClick={(e: MouseEvent) => {
													e.stopPropagation();
													e.preventDefault();

													handleOnChange(item.value);
												}}
												data-selected={defaultValueArray?.some((selectedOption) => selectedOption === item.value)}
												key={item.key}
											>
												<Checkbox
													label={item.label}
													isChecked={defaultValueArray?.some((selectedOption) => selectedOption === item.value)}
												/>
											</Button>
										) : (
											<styled.div
												className={css(classes.option)}
												onClick={() => handleOnChange(item.value)}
												data-value={item.value}
												data-label={item.label}
												data-selected={defaultValueArray?.some((selectedOption) => selectedOption === item.value)}
												key={item.key}
											>
												{item.label}
											</styled.div>
										)
									)}
							</styled.div>
						</Box>
					)}
				</Box>

				{Boolean(isError) && (
					<Box className={css(classes.footer)}>
						<WarningIcon className={css(classes.error)} width={12} height={12} />
						<Text className={css(classes.helperText)} size="xxs">
							{error?.message}
						</Text>
					</Box>
				)}
			</Box>
		);
	}
);
