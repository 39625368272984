import { Text } from '@/components/core/Text/Text';
import { FC } from '@/interfaces/blocks/shared';
import { Box, BoxProps, HStack, VStack } from '@/styled-system/jsx';

export const Divider = (props: BoxProps) => {
	return (
		<Box p={2} {...props}>
			<Box w="4px" h="4px" background="other.border.regular.strong"></Box>
		</Box>
	);
};

export const Categories: FC<{ label?: string; data: Array<{ label: string; url: string }> }> = ({
	label,
	data: categories,
}) => {
	return (
		<VStack alignItems="flex-start" gap={4}>
			{label && (
				<Text size="lg" fontWeight="bold">
					{label}
				</Text>
			)}
			<HStack gap={0} textTransform="uppercase" flexWrap="wrap">
				{categories.map((category, i) => {
					if (!category.label) {
						return null;
					}

					return (
						<>
							<Text
								as="span"
								color="text.regular.default"
								size="sm"
								fontWeight="600"
								width="full"
								maxW="max-content"
								lineHeight="unset"
							>
								{category.label}
							</Text>
							{i !== categories.length - 1 && <Divider></Divider>}
						</>
					);
				})}
			</HStack>
		</VStack>
	);
};
