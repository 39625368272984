import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const navigationDefaultVariants = {}
const navigationCompoundVariants = []

const navigationSlotNames = [
  [
    "backdrop",
    "navigation__backdrop"
  ],
  [
    "positioner",
    "navigation__positioner"
  ],
  [
    "content",
    "navigation__content"
  ],
  [
    "title",
    "navigation__title"
  ]
]
const navigationSlotFns = /* @__PURE__ */ navigationSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, navigationDefaultVariants, getSlotCompoundVariant(navigationCompoundVariants, slotName))])

const navigationFn = memo((props = {}) => {
  return Object.fromEntries(navigationSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const navigationVariantKeys = []

export const navigation = /* @__PURE__ */ Object.assign(navigationFn, {
  __recipe__: false,
  __name__: 'navigation',
  raw: (props) => props,
  variantKeys: navigationVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, navigationVariantKeys)
  },
})