import { decodeWhitelistedHTMLEntities } from '@/components/shared/Hero/components/Hero.utils';
import { ChevronLeftIcon } from '@/icons/ChevronLeftIcon';
import { ChevronRightIcon } from '@/icons/ChevronRightIcon';
import { poly } from '@/libs/poly';
import { cx } from '@/styled-system/css';
import { Flex, styled } from '@/styled-system/jsx';
import { NavigationlinkVariantProps, navigationlink } from '@/styled-system/recipes';
import { token } from '@/styled-system/tokens';
import { logger } from '@/utils/logger';
import NextLink from 'next/link';

import { ComponentProps, ReactNode, forwardRef } from 'react';

const NavigationLinkContainer = styled(poly('button'));
const NavigationNextLinkContainer = styled(poly(NextLink));

type NavigationLinkProps = Omit<ComponentProps<typeof NavigationLinkContainer>, 'color' | 'css'> &
	NavigationlinkVariantProps & {
		children?: ReactNode | undefined;
		orientation?: 'left' | 'right' | 'none';
		description?: string;
		isActive?: boolean;
		href?: string;
		label: string;
	};

export const NavigationLink = forwardRef<HTMLButtonElement, NavigationLinkProps>((props: NavigationLinkProps, ref) => {
	const {
		children,
		className,
		isActive,
		orientation = 'right',
		size,
		as,
		onClick,
		label,
		description,
		...rest
	} = props;

	if (!rest.href && as === 'a') {
		logger.warn(`NavigationLink for ${label} href is undefined`);

		return null;
	}

	const ContainerComponent = as === 'a' ? NavigationNextLinkContainer : NavigationLinkContainer;

	return (
		<ContainerComponent
			data-part="root"
			className={cx(
				navigationlink({
					orientation: orientation === 'none' ? undefined : orientation,
					size,
				}),
				className
			)}
			onClick={onClick}
			ref={ref}
			aria-current={isActive ? 'page' : 'false'}
			_focusVisible={{
				outline: 'none',
			}}
			{...rest}
		>
			<Flex alignItems="center" width="full" justifyContent={orientation === 'right' ? 'space-between' : 'unset'}>
				{orientation === 'left' && <ChevronLeftIcon color={token('colors.actions.links.regular.default')} />}
				<styled.p data-part="label">{label}</styled.p>
				{orientation === 'right' && <ChevronRightIcon />}
			</Flex>
			{description ? <styled.p data-part="description">{decodeWhitelistedHTMLEntities(description)}</styled.p> : null}
		</ContainerComponent>
	);
});
