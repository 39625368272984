'use client';

import { Button } from '@/components/core/Button/Button';
import { Checkbox } from '@/components/core/Checkbox';
import { SelectIcon } from '@/components/core/Select';
import { Text } from '@/components/core/Text/Text';
import { css } from '@/styled-system/css';
import { Box, Flex, FlexProps, VStack, styled } from '@/styled-system/jsx';
import { dropdown } from '@/styles/theme/dropdown';
import { useOutsideClick } from '@/utils/handleOutsideClick';
import { useRef, useState } from 'react';

export interface IDropdownOption extends Record<string, any> {
	label: string;
	value: string;
	disabled?: boolean;
}

interface IDropdownChangeEvent {
	all: Array<IDropdownOption> | null;
	last: IDropdownOption;
}

interface IDropdownProps extends Omit<FlexProps, 'onChange'> {
	options: Array<IDropdownOption>;
	placeholder: string;
	label?: string;
	helperText?: string;
	selectedOptions?: Array<IDropdownOption>;
	onChange?: (item: IDropdownChangeEvent) => void;
	disabled?: boolean;
	readOnly?: boolean;
	multiple?: boolean;
}

export const ArticleFilterDropdown = ({
	options,
	placeholder,
	label,
	helperText,
	selectedOptions = [],
	onChange,
	disabled = false,
	readOnly = false,
	...rest
}: IDropdownProps) => {
	const wrapperRef = useRef<HTMLDivElement>(null);

	const [isOpen, setIsOpen] = useState(false);

	useOutsideClick(wrapperRef, () => setIsOpen(false));

	const handleOnChange = (item: IDropdownOption | null) => {
		if (!item) {
			return;
		}

		const current = (() => {
			const isOptionSelected = selectedOptions.some((option) => option.value === item.value);

			if (isOptionSelected) {
				const newState = selectedOptions.filter((option) => option.value !== item.value);

				return newState;
			} else {
				return [...selectedOptions, item];
			}
		})();

		onChange?.({ all: current, last: item });
	};

	const classes = dropdown.raw({ disabled, readOnly, multiple: true });

	return (
		<Flex className={css(classes.root)} {...rest}>
			{label && (
				<Text className={css(classes.label)} size="xs" fontWeight={600}>
					{label}
				</Text>
			)}
			<Box className={css(classes.select)} ref={wrapperRef}>
				<button className={css(classes.trigger)} onClick={() => setIsOpen(!isOpen)} data-open={isOpen}>
					<Text size="sm" noOfLines={1} fontWeight={selectedOptions.length ? 'semibold' : 'normal'}>
						{placeholder}
					</Text>
					<SelectIcon isOpen={isOpen} className={css(classes.icon)} />
				</button>
				{isOpen && (
					<Box className={css(classes.positioner)} data-open={isOpen}>
						<styled.div
							className={css(classes.content, css.raw({ maxH: 'max(50vh, 300px)', overflowY: 'auto' }))}
							id={label}
						>
							<VStack gap="2">
								{options?.map((option) => (
									<Button
										rootProps={css.raw(
											{
												'& label': {
													whiteSpace: 'initial',
													textAlign: 'start',
												},
											},
											classes.option
										)}
										variant="unstyled"
										key={option.value}
										disabled={option?.disabled}
										data-selected={selectedOptions?.some((selectedOption) => selectedOption.value === option.value)}
									>
										<Checkbox
											isChecked={selectedOptions?.some((selectedOption) => selectedOption.value === option.value)}
											label={option.label}
											disabled={option?.disabled}
											onChange={() => handleOnChange(option)}
										/>
									</Button>
								))}
							</VStack>
						</styled.div>
					</Box>
				)}
			</Box>

			{helperText && (
				<Text className={css(classes.helperText)} size="xxs" color="text.regular.subtitle" fontWeight={600}>
					{helperText}
				</Text>
			)}
		</Flex>
	);
};
