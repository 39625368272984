import { ISearchResultItem } from '@/components/block-templates/ContentSearchBlockTemplate/components/ContentSearchResults';
import { Heading } from '@/components/core/Heading/Heading';
import { Text } from '@/components/core/Text/Text';
import { getIconByMimeType } from '@/const/icon';
import { MimeType } from '@/enums/MimeType';
import { DocumentDOCIcon } from '@/icons/DocumentDOCIcon';
import { FC } from '@/interfaces/blocks/shared';
import { sva } from '@/styled-system/css';
import { Box, Flex, FlexProps, HTMLStyledProps, LinkOverlay, styled } from '@/styled-system/jsx';
import { formatPublicationDateToEuZurich } from '@/utils/i18n/locale-formatting/localeFormatting';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';

interface IContentSearchCardProps extends FlexProps {
	contentSearchTranslations: TranslationLabelValues;
	timezoneTranslations: TranslationLabelValues;
	searchResult: ISearchResultItem;
}

export const ContentSearchCard: FC<IContentSearchCardProps> = ({
	contentSearchTranslations,
	timezoneTranslations,
	searchResult,
	...rest
}) => {
	if (!searchResult) {
		return null;
	}

	const classes = contentSearchCard();
	const thumbnailSrc = searchResult?.pagemap?.cse_thumbnail?.[0]?.src;
	const formattedTitle = searchResult?.title?.split('|')[0]?.trim() ?? '';
	const publishedTime = searchResult?.pagemap?.metatags?.[0]?.['article:published_time'];
	const publishedDate = publishedTime ? new Date(publishedTime) : undefined;
	const publishedTranslation = translate(contentSearchTranslations, 'contentSearch.resultCard.published');

	return (
		<Flex data-part="root" className={classes.root} {...rest}>
			{searchResult?.mime ? (
				<Box data-part="document-icon" className={classes.documentIcon}>
					{getIconByMimeType(searchResult.mime) || <DocumentDOCIcon />}
				</Box>
			) : (
				<ThumbnailImage data-part="thumbnail" className={classes.thumbnail} src={thumbnailSrc} alt={formattedTitle} />
			)}

			<Flex data-part="content" className={classes.content}>
				<LinkOverlay
					href={searchResult?.link}
					target="_blank"
					title={`${translate(contentSearchTranslations, 'contentSearch.resultCard.pageLinkTitle')} ${formattedTitle}`}
				>
					<Heading as="h3" type="sm">
						{formattedTitle}
					</Heading>
				</LinkOverlay>

				<Text size="sm">{searchResult?.snippet}</Text>

				<Box data-part="info" className={classes.info}>
					{publishedDate && (
						<Text as="span" size="sm">
							{formatPublicationDateToEuZurich(publishedDate, publishedTranslation, true, timezoneTranslations)}
						</Text>
					)}

					{searchResult?.mime && (
						<Text as="span" size="sm">
							{getLabelByMimeType(searchResult.mime)}
						</Text>
					)}
				</Box>
			</Flex>
		</Flex>
	);
};

const ThumbnailImage: FC<HTMLStyledProps<'img'>> = ({ src, ...rest }) => {
	if (!src) {
		return null;
	}

	return <styled.img src={src} loading="lazy" {...rest} />;
};

const contentSearchCard = sva({
	slots: ['root', 'documentIcon', 'thumbnail', 'content', 'info'],
	base: {
		root: {
			p: '7',
			gap: '6',
			position: 'relative',
			flexDir: { base: 'column', sm: 'row' },
			borderWidth: '1px',
			borderColor: 'other.border.regular.default',
			borderRadius: 'lg',
			transition: 'background-color 0.3s ease-out',
			backgroundColor: 'surface.interactive.transparent.regular',
			_hover: { backgroundColor: 'surface.interactive.transparent.regular.hover' },
			_active: { backgroundColor: 'surface.interactive.transparent.regular.pressed' },
		},
		documentIcon: {
			'& > svg': {
				width: '56px',
				height: '56px',
			},
		},
		thumbnail: {
			height: '56px',
			width: '56px',
			borderRadius: 'xs',
			objectFit: 'cover',
			flexShrink: '0',
		},
		content: {
			color: 'text.regular.default',
			flexDir: 'column',
			gap: '2',
		},
		info: {
			color: 'text.regular.subtitle',
			mt: '2',
		},
	},
});

export const getLabelByMimeType = (mimeType: MimeType) => {
	switch (mimeType) {
		case MimeType.PDF:
			return 'PDF Document';
		case MimeType.DOC:
			return 'DOC Document';
		case MimeType.DOCX:
			return 'DOCX Document';
		case MimeType.XLS:
			return 'XLS Document';
		case MimeType.XLSX:
			return 'XLSX Document';
		case MimeType.PPT:
			return 'PPT Document';
		case MimeType.PPTX:
			return 'PPTX Document';
		case MimeType.AVI:
			return 'AVI Document';
		case MimeType.TXT:
			return 'TXT Document';
		case MimeType.ZIP:
			return 'ZIP Document';

		default:
			return 'Document';
	}
};
