'use client';

import { useTealiumInstance } from '@/components/shared/TealiumInstanceContainer/useTealiumInstance';
import { disclaimer } from '@/styles/theme/disclaimer';
import { Portal } from '@ark-ui/react';

export interface ISettingsDisclaimerProps {
	open: boolean;
	language: string;
	onClose?: () => void;
}

export const SettingsDisclaimer: React.FC<ISettingsDisclaimerProps> = ({ open, language, onClose }) => {
	const { isTealiumReady } = useTealiumInstance();

	if (!isTealiumReady || !open) {
		return;
	}

	globalThis.utag?.gdpr?.showConsentPreferences(language);

	const { backdrop } = disclaimer({ blur: true });

	const observer = new MutationObserver(() => {
		if (onClose) {
			onClose();
		}
	});

	const target = document.querySelector('.gdpr_privacy_prompt_wrapper');

	if (!target) {
		return;
	}
	observer.observe(target, {
		attributes: true,
	});

	return (
		<Portal>
			<div className={backdrop}></div>
		</Portal>
	);
};
