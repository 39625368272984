import { amethystTheme } from '@/styles/theme/themes/amethyst.theme';
import { defaultTheme } from '@/styles/theme/themes/default.theme';
import { foundationsTheme } from '@/styles/theme/themes/foundations.theme';
import { SiteName } from '@/types/feature-flags';

export const themes = {
	default: defaultTheme,
	amethyst: amethystTheme,
	foundations: foundationsTheme,
};

export type ThemeName = keyof typeof themes;

export const siteNameToThemeMap: Record<SiteName, ThemeName> = {
	// Default
	corpweb: 'default',
	spendenstiftung: 'default',
	vorsorgestiftung: 'default',
	vonsec: 'default',
	tb: 'default',
	volt: 'default',

	// Amethyst
	cosmofunding: 'amethyst',
	deritrade: 'amethyst',

	// Foundations
	lyra: 'foundations',
	vosti: 'foundations',
};
