import { themeName } from '@/constants/global';
import type { FC, SVGAttributes } from 'react';

export const ArrowRightIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	if (themeName === 'foundations') {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...props}>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M26.1742 5.72974V25.2289C26.1742 25.505 25.9503 25.7289 25.6742 25.7289H6.17505V24.7289H24.4671L6.22361 6.48541L6.93072 5.7783L25.1742 24.0217V5.72974H26.1742Z"
					fill="currentColor"
				/>
			</svg>
		);
	}

	return (
		<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="m15 6-1.125 1.125L18 11.25H3v1.5h15l-4.125 4.125L15 18l6-6-6-6Z" fill="currentColor" />
		</svg>
	);
};
