import { IDropdownOption } from '@/components/core/Dropdown/Dropdown';
import { DisclaimerSections } from '@/components/shared/Disclaimer/Disclaimer';
import { CountryCode } from '@/enums/Language';
import { ITrackingInformation } from '@/types/tracking';
import { CustomTrackingEvent, doTrack } from '@/utils/tracking/tracking';

const VISITORROLE_TRANSLATION = 'disclaimer.visitorrole.' as const;

export const DisclaimerOptions = {
	ReloadWhenVisitorChangesDomicileOrRole: 'ReloadWhenVisitorChangesDomicileOrRole',
	FunctionalOnlyOnClose: 'FunctionalOnlyOnClose',
	RedirectWhenVisitorSelectsDomicile: 'RedirectWhenVisitorSelectsDomicile',
};

export enum TealiumConsentState {
	None = 'none',
	AcceptedAll = 'acceptedAll',
	FunctionalOnly = 'functionalOnly',
	AcceptedPartially = 'acceptedPartially',
}

export const getConsentState = (): TealiumConsentState => {
	if (!globalThis.utag || !globalThis.utag.gdpr) {
		return TealiumConsentState.None;
	}

	const cookieValues = globalThis.utag.gdpr.getCookieValues();

	if (!isConsentTrue(cookieValues, 'consent', 'true')) {
		return TealiumConsentState.None;
	}

	if (isAllAccepted()) {
		return TealiumConsentState.AcceptedAll;
	} else if (isFunctionalAccepted()) {
		return TealiumConsentState.FunctionalOnly;
	} else {
		return TealiumConsentState.AcceptedPartially;
	}
};

export const acceptCookies = (trackingTarget: HTMLElement, consentState: TealiumConsentState): void => {
	const originalEvent = CustomTrackingEvent.FromTarget(trackingTarget, consentState);

	doTrack(originalEvent);
};

export const getDisclaimerTrackingInformation = (): { [event: string]: ITrackingInformation } => {
	const other = {
		category: 'disclaimer',
		nonInteraction: false,
		techCategory: 'dialog',
	};

	return {
		acceptedAll: {
			action: 'accept-all',
			conversionId: 'vt-disclaimer',
			...other,
		},
		acceptedPartially: {
			action: 'accept',
			conversionId: 'vt-disclaimer',
			...other,
		},
		functionalOnly: {
			action: 'accept-functional-only',
			conversionId: 'vt-disclaimer',
			...other,
		},
		settingsShow: {
			action: 'settings',
			...other,
		},
		show: {
			action: 'impression',
			...other,
		},
	};
};

const getTealiumConsentManagerCookie = (): string | undefined => {
	return getCookie('CONSENTMGR');
};

const getCookie = (cookieKey: string): string | undefined => {
	if (typeof document !== 'undefined') {
		return (
			document.cookie
				.split(';')
				.map((cookie: string) => cookie.trim())
				.find((cookie: string) => cookie.startsWith(cookieKey + '='))
				?.split('=')
				.at(1) ?? ''
		);
	}
};

export const tealiumConsentExists = () => {
	const consentmgrCookie = getTealiumConsentManagerCookie();
	const regex = /consent:(true|false)/;
	const match = consentmgrCookie?.match(regex);

	if (!match) {
		return false;
	}

	const consentObj = Object.fromEntries([match?.[0].split(':')]);

	return consentObj.consent === 'true';
};

export enum TermsCookieStatus {
	Unset,
	Accepted,
	Declined,
}

export const getTermsCookieStatus = (cookieKey: string | undefined) => {
	if (!cookieKey) {
		return TermsCookieStatus.Unset;
	}

	const termsCookie = getCookie(cookieKey);

	if (!termsCookie) {
		return TermsCookieStatus.Unset;
	}

	if (termsCookie === 'Accepted') {
		return TermsCookieStatus.Accepted;
	}

	return TermsCookieStatus.Declined;
};

export const setTermsCookie = (cookieKey: string | undefined, accepted: boolean) => {
	if (!cookieKey) {
		return;
	}

	const expireDate = new Date();
	const time = expireDate.getTime();
	const expireTime = time + 1000 * 60 * 60 * 24 * 365; // 1 year

	expireDate.setTime(expireTime);
	document.cookie = `${cookieKey}=${accepted ? 'Accepted' : 'Declined'}; path=/;expires=${expireDate.toUTCString()};SameSite=Strict`;
};

export const shouldDisclaimerOpen = (
	displaySection: DisclaimerSections | undefined,
	customTermsCookieKey: string | undefined
) => {
	switch (displaySection) {
		case DisclaimerSections.COOKIE:
			return shouldCookieDisclaimerOpen();
		case DisclaimerSections.LEGAL:
			return shouldLegalDisclaimerOpen(customTermsCookieKey);
		default:
			return shouldCookieDisclaimerOpen() || shouldLegalDisclaimerOpen(customTermsCookieKey);
	}
};

const shouldCookieDisclaimerOpen = (): boolean => {
	return !tealiumConsentExists();
};

const shouldLegalDisclaimerOpen = (customTermsCookieKey: string | undefined) => {
	return getTermsCookieStatus(customTermsCookieKey) !== TermsCookieStatus.Accepted;
};

const isAllAccepted = (): boolean => {
	if (!globalThis?.utag?.gdpr) {
		return false;
	}

	const cookieValues = globalThis.utag.gdpr.getCookieValues();

	return (
		isConsentTrue(cookieValues, 'c1') &&
		isConsentTrue(cookieValues, 'c3') &&
		isConsentTrue(cookieValues, 'c6') &&
		isConsentTrue(cookieValues, 'c9')
	);
};

const isFunctionalAccepted = (): boolean => {
	if (!globalThis?.utag?.gdpr) {
		return false;
	}

	const cookieValues = globalThis.utag.gdpr.getCookieValues();

	return (
		isConsentTrue(cookieValues, 'c9') &&
		!(
			isConsentTrue(cookieValues, 'c1') ||
			isConsentTrue(cookieValues, 'c2') ||
			isConsentTrue(cookieValues, 'c3') ||
			isConsentTrue(cookieValues, 'c4') ||
			isConsentTrue(cookieValues, 'c5') ||
			isConsentTrue(cookieValues, 'c6') ||
			isConsentTrue(cookieValues, 'c7') ||
			isConsentTrue(cookieValues, 'c8') ||
			isConsentTrue(cookieValues, 'c10') ||
			isConsentTrue(cookieValues, 'c11') ||
			isConsentTrue(cookieValues, 'c12') ||
			isConsentTrue(cookieValues, 'c13') ||
			isConsentTrue(cookieValues, 'c14') ||
			isConsentTrue(cookieValues, 'c15')
		)
	);
};

function isConsentTrue(coookieValues: { [key: string]: string }, prop: string, value: number | string = 1) {
	// eslint-disable-next-line no-prototype-builtins
	return coookieValues.hasOwnProperty(prop) && coookieValues[prop] == value;
}

const visitorRoleOrder = ['privateclient', 'institutionalclient', 'externalassetmanager', 'other'];

export function getVisitorRoles(translations: { [key: string]: string }): Array<IDropdownOption> {
	return (
		Object.entries(translations)
			?.filter(([key, _]) => key.startsWith(VISITORROLE_TRANSLATION))
			?.map(([key, value]) => ({ value: key, label: value }))
			?.map((option) => ({
				value: option.value.slice(VISITORROLE_TRANSLATION.length, option.value.length),
				label: option.label,
			}))
			?.sort((a, b) => {
				return visitorRoleOrder.indexOf(a.value) - visitorRoleOrder.indexOf(b.value);
			}) ?? []
	);
}

export interface ICountrySelectionItem {
	country?: string;
	mapToGlobalCountry?: boolean;
}

export function getCountries(
	countriesListForVisitorDomicile: ICountrySelectionItem[],
	translations: { [key: string]: string }
): Array<IDropdownOption> {
	let filteredCountries = countriesListForVisitorDomicile;
	let countriesOptions: Array<IDropdownOption> = [];

	// Filter out countries that are mapped that are intended to be under global
	filteredCountries = filteredCountries?.filter((country) => !country.mapToGlobalCountry);

	// Filter out global and international
	filteredCountries = filteredCountries?.filter(
		(country) =>
			country.country?.toLowerCase() !== CountryCode.GLOBAL.toLowerCase() &&
			country.country?.toLowerCase() !== CountryCode.INTERNATIONAL.toLowerCase()
	);

	// Add one global value in the filtered list
	filteredCountries.push({
		country: CountryCode.INTERNATIONAL,
		mapToGlobalCountry: false,
	});

	// Filter out countries that have no translation
	filteredCountries = filteredCountries?.filter((country) =>
		Boolean(translations[`country.${country.country?.toLowerCase()}`]?.length)
	);

	// Map countries to dropdown options
	countriesOptions = filteredCountries.map(({ country }) => ({
		label: translations[`country.${country?.toLowerCase()}`] ?? '',
		value: country ?? '',
	}));

	// Sort countries by label and always include global at the end
	countriesOptions = countriesOptions?.sort((a, b) => {
		if (a.value.toLowerCase() === CountryCode.INTERNATIONAL.toLocaleLowerCase()) {
			return 1;
		}

		if (b.value.toLowerCase() === CountryCode.INTERNATIONAL.toLocaleLowerCase()) {
			return -1;
		}

		return a.label.localeCompare(b.label);
	});

	return countriesOptions;
}

export function getDefaultSelectedDomicile(
	countrySelectItems: IDropdownOption[],
	selectedCountry: string | undefined
): IDropdownOption {
	return (
		countrySelectItems
			.filter((item) =>
				selectedCountry && selectedCountry !== CountryCode.GLOBAL
					? item.value.toLowerCase() === selectedCountry.toLowerCase()
					: item.value.toLowerCase() === CountryCode.INTERNATIONAL
			)
			?.at(0) ?? countrySelectItems[0]
	);
}

export function convertToCountry(item: IDropdownOption | null): string {
	if (!item || item.value.toLowerCase() === CountryCode.INTERNATIONAL) {
		return CountryCode.GLOBAL;
	}

	return item.value;
}
