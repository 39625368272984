import { FC, SVGAttributes } from 'react';

export const ClearIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	return (
		<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="m4.52051 10.9408.53841.5384L7.9998 8.54089l2.941 2.93831.5384-.5384-2.94091-2.94093 2.94091-2.94095-.5384-.53841-2.94093 2.94095-2.94095-2.94095-.53841.53841 2.94095 2.94095-2.94095 2.94093Z"
					fill="currentColor"
				/>
				<circle cx="8" cy="8" r="6.5" stroke="currentColor" />
			</svg>
		</svg>
	);
};
