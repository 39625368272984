import { elementWithDisplayOptions } from '@/components/shared/ElementWithDisplayOptions/ElementWithDisplayOptions';
import { Box, BoxProps } from '@/styled-system/jsx';
import { BreakpointToken, SpacingToken, token } from '@/styled-system/tokens';
import { DisplayOption } from '@/types/display-options';
import { ReactNode } from 'react';

type SpacingParameter = Partial<Record<BreakpointToken, SpacingToken>>;

export const displayOptionsCSSVariablesProvider = (spacing?: SpacingParameter, baseSpacing: SpacingToken = '4') => {
	const baseSpacingToken = token.var(`spacing.${baseSpacing}`);

	return {
		'--display-options-spacing-md': spacing?.md ? token.var(`spacing.${spacing?.md}`) : baseSpacingToken,
		'--display-options-spacing-lg': spacing?.lg ? token.var(`spacing.${spacing?.lg}`) : baseSpacingToken,
	};
};

interface IElementWithDisplayOptionsWrapperProps extends BoxProps {
	displayOption: DisplayOption;
	children: ReactNode;
}

export const ElementWithDisplayOptionsWrapper = ({
	displayOption,
	children,
	...rest
}: IElementWithDisplayOptionsWrapperProps) => {
	return (
		<Box
			className={elementWithDisplayOptions({
				displayOption,
			})}
			{...rest}
		>
			{children}
		</Box>
	);
};
