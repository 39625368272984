'use client';

import { ScrollTracker } from '@/components/shared/TealiumInstanceContainer/components/ScrollTracker';
import {
	ITealiumInstance,
	TealiumInstanceContext,
} from '@/components/shared/TealiumInstanceContainer/useTealiumInstance';
import { ITrackingSettingsContent } from '@/interfaces/settings';
import Script from 'next/script';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

export interface ITealiumInstanceContainerProps {
	children: ReactNode;
	trackingSettings?: ITrackingSettingsContent;
	nonce?: string;
}

function getTealiumScriptURL(trackingSettings: ITrackingSettingsContent): string {
	const domain = trackingSettings?.tealiumSettings?.domain;
	const account = trackingSettings?.tealiumSettings?.account;
	const baseUrl = 'https://' + domain ?? `tags.tiqcdn.com/utag/${account}`;

	const profile = trackingSettings?.tealiumSettings?.profile;
	const environment = trackingSettings?.tealiumSettings?.environment;

	return `${baseUrl}/${profile}/${environment}/utag.js`;
}

export const TealiumInstanceProvider: React.FC<ITealiumInstanceContainerProps> = ({
	children,
	trackingSettings,
	nonce,
}) => {
	const [isTealiumLoaded, setIsTealiumLoaded] = useState(!!globalThis.utag);
	const [isPageEventTriggered, setIsPageEventTriggered] = useState(false);

	const isTealiumListenerSet = useRef(false);

	// circumvent double execution of useEffect callback due to React StrictMode
	const isPageCleanedUp = useRef(false);

	const tealiumReadyListener = () => {
		setIsTealiumLoaded(true);
	};

	if (typeof document !== 'undefined' && !isTealiumListenerSet.current) {
		document.addEventListener('tealium_ready', tealiumReadyListener);
		isTealiumListenerSet.current = true;
	}

	const tealiumInstance: ITealiumInstance = useMemo(
		() => ({
			isTealiumReady: isTealiumLoaded && isPageEventTriggered,
			utag: globalThis.utag,
		}),
		[isTealiumLoaded, isPageEventTriggered]
	);

	const setupPage = () => {
		if (!isTealiumLoaded || !trackingSettings) {
			return;
		}

		utag?.view(globalThis?.utag_data);
		setIsPageEventTriggered(true);
	};

	const cleanUpPage = () => {
		if (!isTealiumLoaded || isPageCleanedUp.current) {
			return;
		}

		document.removeEventListener('tealium_ready', tealiumReadyListener);

		// When page is unloaded trigger SPA context switch (once), this will reset e.g. time-spent-on-page
		globalThis.utag?.ext.genericEventDispatcher('spa-context-switch', document);
		isPageCleanedUp.current = true;
	};

	useEffect(() => {
		setupPage();

		return cleanUpPage;
	}, [isTealiumLoaded]);

	if (!trackingSettings) {
		return <TealiumInstanceContext.Provider value={tealiumInstance}>{children}</TealiumInstanceContext.Provider>;
	}

	return (
		<>
			<Script nonce={nonce} src={getTealiumScriptURL(trackingSettings)}></Script>
			{isTealiumLoaded && <ScrollTracker />}
			<TealiumInstanceContext.Provider value={tealiumInstance}>{children}</TealiumInstanceContext.Provider>
		</>
	);
};
