'use client';

import { ITrackedContextValue, TrackedContext } from '@/components/shared/tracking/Tracked.context';
import {
	ITrackingInformation,
	ITrackedBlockInformation,
	TrackingUseCase,
	ITrackingTarget,
	ITrackingEventData,
	ITrackingHref,
} from '@/types/tracking';
import { mergeTrackingInformation } from '@/utils/tracking/tracking';
import { ReactNode, useContext } from 'react';

export interface ITrackedContainerProps {
	children: ReactNode;

	/**
	 * Tracking information of this tracked block. It is intended that this tracking information is the same for every block.
	 * This information will be overriden, if the properties in the cmsTrackingInformation are set.
	 *
	 * Expects a dictionary, which key values are the types of the DOM events triggering the tracking. Depending on the implementation these may be
	 * standard DOM-events or custom DOM events.
	 *
	 * @example trackingInformation: {click: {action: "click", techCategory: "link"}}
	 * @example trackingInformation: {stepBack: {action: "click", techCategory: "form"}, submit: {"action": "submit", techCategory: "form"}}
	 */
	trackingInformation?: { [eventName: string]: ITrackingInformation };

	/**
	 * The Block information of this tracked Component.
	 * This tracking information is passed by the cms and has priority over all initially set values.
	 */
	cmsTrackingInformation?: ITrackingInformation;

	/**
	 * The tracked Block information is information regarding CMS Ids and names of the block.
	 * See the documentation of ITrackedBlockInformation for more insights.
	 */
	trackedBlockInformation?: ITrackedBlockInformation;

	/**
	 * If set, the generated tracking information when calling the doTrack method will default to the tracking use case generated data.
	 */
	trackingUseCase?: TrackingUseCase;

	/**
	 * Contains information about the target of the tracked link like the CMS-ID or filename.
	 * See the Documentaiton of ITrackingTarget for more information.
	 */
	trackingTarget?: ITrackingTarget;

	/**
	 * The href is a url And/or anchor link to be passed to the automated tracking service. When set this will provide auto generated tracking information.
	 * Its 'absoluteHref' property will be set as targetUrl in the tracking information and should be prepared server side.
	 * See the documentation of the aumomatedTracking module.
	 * Note that the automated tracking data will be only collected when the original event was a DOM event.
	 */
	href?: ITrackingHref;
}

/**
 * Component for holding tracking information only. No tracking Events are attached to this element.
 * @param param0
 * @returns
 */
export const TrackedContainer: React.FC<ITrackedContainerProps> = ({
	children,
	trackedBlockInformation,
	cmsTrackingInformation,
	trackingInformation,
	trackingUseCase,
	href,
	trackingTarget,
}) => {
	const parentContext: ITrackedContextValue = useContext(TrackedContext);

	const collectTrackingData = (eventName: string): ITrackingEventData => {
		const parentData: ITrackingEventData = parentContext.collectTrackingData(eventName);

		const levels = parentData.levels;

		if (trackedBlockInformation) {
			levels.push(trackedBlockInformation);
		}

		const component: ITrackedBlockInformation | undefined = trackedBlockInformation?.blockIsRelevant
			? trackedBlockInformation
			: parentData.component;

		let trackingInfo: ITrackingInformation = {};

		// Only merge href when event is clickEvent.
		if (eventName == 'click') {
			trackingInfo = mergeTrackingInformation(
				cmsTrackingInformation,
				trackingInformation ? trackingInformation[eventName] : undefined,
				trackingUseCase,
				href,
				trackingTarget,
				trackedBlockInformation
			);
		} else {
			trackingInfo = mergeTrackingInformation(
				cmsTrackingInformation,
				trackingInformation ? trackingInformation[eventName] : undefined,
				trackingUseCase,
				undefined,
				trackingTarget,
				trackedBlockInformation
			);
		}

		trackingInfo = mergeTrackingInformation(trackingInfo, parentData.event);

		return {
			component,
			levels,
			nestingLevel: (parentData.nestingLevel ?? -1) + 1,
			event: trackingInfo,
		};
	};

	const myTrackedContextValue: ITrackedContextValue = {
		collectTrackingData,
	};

	return <TrackedContext.Provider value={myTrackedContextValue}>{children}</TrackedContext.Provider>;
};
