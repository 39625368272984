import { FC, SVGAttributes } from 'react';

export const TrashIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	return (
		<svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M14.7048 6.47614V4.70472C14.7048 4.419 14.4762 4.19043 14.1905 4.19043H9.80952C9.5238 4.19043 9.29523 4.419 9.29523 4.70472V6.47614H5.74475C5.45904 6.47614 5.23047 6.70472 5.23047 6.99043V8.27043C5.23047 8.50281 5.38285 8.6971 5.59618 8.76186L7.23809 19.3714C7.27618 19.6228 7.49333 19.8095 7.74856 19.8095H16.2552C16.5105 19.8095 16.7276 19.6228 16.7657 19.3714L18.4076 8.76186C18.6171 8.6971 18.7733 8.50281 18.7733 8.27043V6.99043C18.7733 6.70472 18.5409 6.47614 18.259 6.47614H14.7048ZM13.5619 5.33329V6.47614H10.4381V5.33329H13.5619ZM8.2819 18.6666L6.80761 8.76186H17.1962L15.7219 18.6666H8.2819Z"
				fill="currentColor"
			/>
		</svg>
	);
};
