import { themeName } from '@/constants/global';
import type { SVGAttributes } from 'react';

export const PlusIcon = (props: SVGAttributes<SVGElement>) => {
	if (themeName === 'foundations') {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...props}>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M15.5395 15.7489V6.1748H16.5395V15.7489H26.0007V16.7489H16.5395V26.1748H15.5395V16.7489H6.00073V15.7489H15.5395Z"
					fill="currentColor"
				/>
			</svg>
		);
	}

	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path fill="currentColor" d="M12.75 4.125h-1.5v7.125H4.125v1.5h7.125v7.125h1.5V12.75h7.125v-1.5H12.75V4.125Z" />
		</svg>
	);
};
