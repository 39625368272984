import { themeName } from '@/constants/global';
import { FC, SVGAttributes } from 'react';

export const CloseIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	if (themeName === 'foundations') {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...props}>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16.7068 16.0036L25.9018 25.1986L25.1947 25.9057L15.9997 16.7107L6.80476 25.9057L6.09766 25.1986L15.2926 16.0036L6.09766 6.80867L6.80476 6.10156L15.9997 15.2965L25.1947 6.10156L25.9018 6.80867L16.7068 16.0036Z"
					fill="currentColor"
				/>
			</svg>
		);
	}

	return (
		<svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M10.0927 23.0248L15.9746 17.1479L21.8565 23.0248L22.9333 21.9479L17.0514 16.066L22.9333 10.1841L21.8565 9.1073L15.9746 14.9892L10.0927 9.1073L9.01587 10.1841L14.8978 16.066L9.01587 21.9479L10.0927 23.0248Z"
				fill="currentColor"
			/>
		</svg>
	);
};
