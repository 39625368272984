import { toIdentifier } from '@/components/block-templates/Jobs/JobFilterBlock/JobFilterBlockTemplate.utils';
import { IDropdownOption } from '@/components/core/Dropdown/Dropdown';
import { Tags } from '@/components/core/Tags/Tags';
import useQueryParams from '@/hooks/useQueryParams';
import { FC } from '@/interfaces/blocks/shared';
import { omit } from 'lodash';

interface IJobFilterTagsProps {
	filters: {
		locations: Array<IDropdownOption>;
		departments: Array<IDropdownOption>;
	};
}

export interface Job {
	title: string;
	startPublish: string;
	jobOrgGf: string;
	locationDescription: string;
	url: string;
}

export const JobFilterTags: FC<IJobFilterTagsProps> = ({ filters }) => {
	const { query, change } = useQueryParams();

	if (!query || Object.values(query).length === 0) {
		return <Tags.Root />;
	}

	const data = (() => {
		const mergedQueryValues = [...(query?.departments || []), ...(query?.locations || [])];

		const selectData = [...filters?.departments, ...filters?.locations];

		return selectData?.filter((option) => mergedQueryValues?.includes(option.value));
	})();

	const removeItemFromQuery = (options: IDropdownOption | string) => {
		if (typeof options === 'string') {
			const search = options;
			const newQuery = omit(query, [search]);

			change(newQuery);

			return;
		}

		const newFilterQuery = Object.keys(query).reduce((acc, key) => {
			if (query[key].includes(options.value)) {
				const newQuery = (query[key] as Array<string>).filter((item) => item !== options.value);

				if (newQuery.length === 0) {
					return acc;
				}

				return { ...acc, [key]: newQuery };
			}

			return {
				...acc,
				[key]: query[key],
			};
		}, {});

		change(newFilterQuery);
	};

	return (
		<Tags.Root>
			{data?.map((option) => {
				return (
					<Tags.Item key={option.value}>
						<Tags.Label>{option.label}</Tags.Label>
						<Tags.CloseButton
							onClick={(e: MouseEvent) => {
								e.stopPropagation();
								removeItemFromQuery(option);
							}}
						/>
					</Tags.Item>
				);
			})}
		</Tags.Root>
	);
};

function preprocessString(str: string) {
	return str.toLowerCase().replace(/[^a-z0-9]/g, '');
}

export const filterJobResults = (jobs: Array<Job>, query: Record<string, string[]>) => {
	let filteredJobs = jobs;

	if (query?.search) {
		const searchStrings = query.search[0];

		filteredJobs = filteredJobs.filter((job) => {
			if (performFuzzyComparison(searchStrings, job.title)) {
				return true;
			}

			if (performFuzzyComparison(searchStrings, job.locationDescription)) {
				return true;
			}

			if (performFuzzyComparison(searchStrings, job.jobOrgGf)) {
				return true;
			}

			return false;
		});
	}

	if (query?.locations) {
		filteredJobs = filteredJobs.filter((job) => query.locations.includes(toIdentifier(job.locationDescription)));
	}

	if (query?.departments) {
		filteredJobs = filteredJobs.filter((job) => query.departments.includes(toIdentifier(job.jobOrgGf)));
	}

	return filteredJobs;
};

export const areJobFiltersApplied = (query: Record<string, string[]>) => {
	return Boolean(query?.search || query?.locations || query?.departments);
};

function performFuzzyComparison(str1: string, str2: string) {
	const processedSearchString = preprocessString(str1);
	const processedText = preprocessString(str2);

	return processedText.includes(processedSearchString);
}
