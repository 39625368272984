'use client';

import { cmsHost } from '@/constants/global';

import { useRef } from 'react';

import { Select } from '@/components/core/Select';
import { HealthCheckApp, ICheckBoxProps } from '@vontobel/healthcheck';

import { IAccordionRecipeProps } from '@/components/core/Accordion/Accordion';
import { accordionRecipe } from '@/components/core/Accordion/Accordion.recipe';
import { AccordionItem } from '@/components/core/Accordion/components/AccordionItem';
import { SelectTriggerLabelType } from '@/enums/Select';
import { AccordionRoot as Accordion } from '@ark-ui/react';

import { IFormContainerBlockContent } from '@/components/block-templates/Forms/FormContainerBlockTemplate/FormContainerBlockTemplate';
import { Checkbox } from '@/components/core/Checkbox';
import { Input } from '@/components/core/Input/Input';
import { getSalesforceConfiguration } from '@/components/shared/WealthCheck/WealthCheck.utils';
import { Tracked } from '@/components/shared/tracking/Tracked';
import { TextFormFieldDisplayMode } from '@/interfaces/blocks/forms';
import { IPageData } from '@/interfaces/coreInformation';
import { getTrackingPropertiesFromCMSBlock } from '@/utils/tracking/tracking';
import { ITextInputProps } from '@vontobel/healthcheck/build/module/components/healthcheck/textbox/textbox.contracts';
import '../../../../node_modules/@vontobel/healthcheck/build/css/all.min.css';
import { useTealiumInstance } from '@/components/shared/TealiumInstanceContainer/useTealiumInstance';

export interface IWealthCheckProps {
	showHeader: boolean;
	debug: boolean;
	testingMode: boolean;
	translations: { [key: string]: string };
	formContainer: IFormContainerBlockContent;
	pageData: IPageData;
}

export const WealthCheck: React.FC<IWealthCheckProps> = ({ translations, formContainer, pageData, ...rest }) => {
	const { utag, isTealiumReady } = useTealiumInstance();
	const trackingRef = useRef<HTMLDivElement>(null);
	const wealthCheckBlock = formContainer?.formSalesforce?.at(0);

	if (!wealthCheckBlock) {
		return;
	}

	const locale = wealthCheckBlock?.contentLink?.expanded?.language?.name;
	const windowHost = typeof window !== 'undefined' ? window.location.host : '';

	return utag && isTealiumReady ? (
		<Tracked
			trackingElementRef={trackingRef}
			trackingInformation={{
				hcInteraction: { category: 'wealthcheck', techCategory: 'interactive-element', nonInteraction: false },
			}}
			{...getTrackingPropertiesFromCMSBlock(wealthCheckBlock?.contentLink?.expanded)}
		>
			<HealthCheckApp
				baseUrl={cmsHost ?? windowHost}
				culture={locale}
				translations={translations}
				pageId={0}
				salesforceConfigurationNew={getSalesforceConfiguration(formContainer, pageData?.id, utag)}
				trackingElementRef={trackingRef}
				showOpener={true}
				injectedElements={{
					Dropdown: (props) => (
						<Select {...(props as any)} variant="defaultLight" selectTriggerLabelType={SelectTriggerLabelType.VALUE} />
					),
					Accordion: ({ id, ...rest }) => <WealthCheckAccordion id={id?.toString()} {...rest} />,
					AccordionItem: (props) => (
						<AccordionItem {...props} classes={accordionRecipe({ size: 'lg', variant: 'light' })} />
					),
					TextInput: WealthCheckInput,
					CheckBox: WealthCheckCheckbox,
				}}
				{...rest}
			/>
		</Tracked>
	) : null;
};

const WealthCheckCheckbox: React.FC<ICheckBoxProps> = ({ defaultChecked, name, label, onChange }) => {
	return (
		<Checkbox
			label={label}
			defaultChecked={defaultChecked}
			name={name}
			id={name}
			onChange={(ev) => onChange(ev.target.value === 'true')}
		/>
	);
};

const WealthCheckInput: React.FC<ITextInputProps> = ({ label, onChange, value, isInvalid }) => {
	return (
		<Input
			displayMode={TextFormFieldDisplayMode.TEXT}
			isError={isInvalid}
			error={{ type: 'validate' }}
			value={value}
			onChange={(e) => {
				onChange && onChange(e?.target?.value);
			}}
			placeholder={label}
			variant="md"
		></Input>
	);
};

const WealthCheckAccordion: React.FC<IAccordionRecipeProps> = ({ size, variant, children }) => {
	const classes = accordionRecipe({ size, variant });

	return (
		<Accordion className={classes.root} multiple>
			{children}
		</Accordion>
	);
};
