'use client';

import { Input } from '@/components/core/Input/Input';
import { TextArea } from '@/components/core/TextArea/TextArea';
import { ITextFormFieldResponse, TextFormFieldDisplayMode } from '@/interfaces/blocks/forms';
import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface ITextFormFieldProps {
	field: ITextFormFieldResponse;
	translations: { [key: string]: string };
	// eslint-disable-next-line @typescript-eslint/ban-types
	formPersonalizationHandler?: Function;
}

const getFormElement = (displayMode?: TextFormFieldDisplayMode) => {
	switch (displayMode) {
		case TextFormFieldDisplayMode.LONGTEXT:
			return TextArea;
		case TextFormFieldDisplayMode.TEXT:
		case TextFormFieldDisplayMode.EMAIL:
		case TextFormFieldDisplayMode.PHONE_NUMBER:
			return Input;
		default:
			return Fragment;
	}
};

export const TextFormField: FC<ITextFormFieldProps> = ({ field, translations, formPersonalizationHandler }) => {
	const { control, setValue, trigger } = useFormContext();

	if (!field?.contentLink?.expanded) {
		return;
	}

	const id = field.contentLink.id;
	const {
		displayMode,
		fieldLabel,
		placeholderText,
		predefinedValue,
		fieldName,
		textMaxLength,
		isMandatory,
		mandatoryErrorMessage,
		isHidden,
		makeReadOnlyIfHasInitialValue,
	} = field.contentLink.expanded;

	const FormElement = getFormElement(displayMode);

	let inputPattern: RegExp | undefined;
	let translationKey = '';

	switch (displayMode) {
		case TextFormFieldDisplayMode.PHONE_NUMBER:
			inputPattern = /^\+[1-9]\d{1,16}$/;
			translationKey = 'forms.validation.phone-invalid.text';
			break;
		case TextFormFieldDisplayMode.EMAIL:
			inputPattern = /^\w+([-+.']*\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
			translationKey = 'forms.validation.email-invalid.text';
			break;
	}
	const validationErrorMessage: string = translationKey ? translations[translationKey] ?? translationKey : '';
	const name = fieldName || String(id);

	return (
		<Controller
			control={control}
			name={name}
			rules={{
				maxLength: textMaxLength,
				required: { value: Boolean(isMandatory), message: mandatoryErrorMessage ?? '' },
				pattern: inputPattern && { value: inputPattern, message: validationErrorMessage ?? '' },
			}}
			render={({ field: { onChange, onBlur, value, ref }, formState: { isSubmitting }, fieldState: { error } }) => {
				return (
					<FormElement
						ref={ref}
						label={fieldLabel}
						displayMode={displayMode}
						isMandatory={isMandatory}
						placeholder={placeholderText}
						error={error}
						value={value}
						onBlur={() => {
							trigger(name);

							formPersonalizationHandler && formPersonalizationHandler(name, value);
							onBlur && onBlur();
						}}
						onChange={(e) => (textMaxLength ? e.target.value.length <= textMaxLength && onChange(e) : onChange(e))}
						readOnly={Boolean(makeReadOnlyIfHasInitialValue) && Boolean(predefinedValue)}
						isError={Boolean(error)}
						hidden={Boolean(isHidden)}
						clearInput={() => setValue(fieldName || String(id), '')}
						translations={translations}
						disabled={isSubmitting}
					/>
				);
			}}
			key={field.contentLink.id}
		/>
	);
};
