import type { SVGAttributes } from 'react';

export const FacebookIcon = (props: SVGAttributes<SVGElement>) => (
	<svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fill="currentColor"
			d="M10.378 19.875H13.261L13.2174 12.6703H15.6208L15.9805 9.87457H13.2174V8.09247C13.2174 7.2859 13.4408 6.73547 14.6017 6.73547H16.0786V4.234C15.8225 4.2013 14.945 4.125 13.9259 4.125C11.7949 4.125 10.3344 5.42751 10.3344 7.81453V9.87457H7.92004V12.6703H10.3344L10.378 19.875Z"
		/>
	</svg>
);
