import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const tableFn = /* @__PURE__ */ createRecipe('table', {
  "size": "md",
  "variant": "primary"
}, [])

const tableVariantMap = {
  "size": [
    "md"
  ],
  "variant": [
    "primary"
  ]
}

const tableVariantKeys = Object.keys(tableVariantMap)

export const table = /* @__PURE__ */ Object.assign(memo(tableFn), {
  __recipe__: true,
  __name__: 'table',
  raw: (props) => props,
  variantKeys: tableVariantKeys,
  variantMap: tableVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, tableVariantKeys)
  },
})