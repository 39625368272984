import { themeName } from '@/constants/global';
import type { SVGAttributes } from 'react';

export const MinusIcon = (props: SVGAttributes<SVGElement>) => {
	if (themeName === 'foundations') {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...props}>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.00073 15.749H26.0007V16.749H6.00073V15.749Z"
					fill="currentColor"
				/>
			</svg>
		);
	}

	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path fill="currentColor" d="M12.75 11.25H4.125v1.5h15.75v-1.5H12.75Z" />
		</svg>
	);
};
