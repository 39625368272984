'use client';

import { Text } from '@/components/core/Text/Text';
import { Tracked } from '@/components/shared/tracking/Tracked';
import { MinusIcon } from '@/icons/MinusIcon';
import { PlusIcon } from '@/icons/PlusIcon';
import { IBlockContent, IBlockResponse } from '@/interfaces/coreInformation';
import { EventAttachMode, ITrackedBlockInformation, ITrackingInformation } from '@/types/tracking';
import { CustomTrackingEvent, doTrack } from '@/utils/tracking/tracking';

import { AccordionItemContent, AccordionItemTrigger, AccordionItem as ArkAccordionItem } from '@ark-ui/react/accordion';
import { FC, ReactNode, RefObject, useRef } from 'react';

export interface IAccordionItemProps extends Partial<IBlockContent> {
	value: string;
	title: string;
	classes: Record<string, string>;
	content?: Array<IBlockResponse<any>>;
	children: Array<ReactNode> | ReactNode;
	trackedBlockInformation?: ITrackedBlockInformation;
	cmsTrackingInformation?: ITrackingInformation;
	trackingRef?: React.RefObject<HTMLDivElement>;
}

const AccordionIcon = (props: { isOpen: boolean }) => {
	return props.isOpen ? <MinusIcon width="24px" height="24px" /> : <PlusIcon width="24px" height="24px" />;
};

export const AccordionItem: FC<IAccordionItemProps> = ({
	value,
	title,
	classes,
	children,
	trackedBlockInformation,
	cmsTrackingInformation,
}) => {
	const trackingRef = useRef<HTMLDivElement>(null);

	const trackChangeEvent = (isClosed: boolean) => {
		if (!trackingRef?.current) {
			return null;
		}

		const event = CustomTrackingEvent.FromTarget(trackingRef.current, 'change');

		const trackData: ITrackingInformation = {
			label: `${trackedBlockInformation?.blockInstanceId} | ${trackedBlockInformation?.blockInstanceName}`,
			action: isClosed ? 'close' : 'open',
			targetUrl: typeof window !== 'undefined' ? window.location.href : '',
		};

		if (!isClosed) {
			trackData.conversionId = cmsTrackingInformation?.conversionId ?? 'vt-tab-accordion';
		}

		doTrack(event, trackData);
	};

	return (
		<Tracked
			trackingElementRef={trackingRef}
			trackingInformation={{
				change: {
					category: 'accordion',
					techCategory: 'tab',
					nonInteraction: false,
					targetUrl: typeof window !== 'undefined' ? window.location.href : '',
				},
			}}
			trackedBlockInformation={trackedBlockInformation}
			attachTrackingEvent={EventAttachMode.Custom}
		>
			<ArkAccordionItem
				id={value}
				value={value}
				className={classes.item}
				ref={trackingRef as RefObject<HTMLDivElement>}
			>
				{({ isOpen }) => {
					return (
						<>
							<AccordionItemTrigger className={classes.trigger} onClick={() => trackChangeEvent(isOpen)}>
								<Text as="span" id={value} flex="1">
									{title}
								</Text>
								<AccordionIcon isOpen={isOpen} />
							</AccordionItemTrigger>
							<AccordionItemContent className={classes.content}>{children}</AccordionItemContent>
						</>
					);
				}}
			</ArkAccordionItem>
		</Tracked>
	);
};
