import { cva } from '@/styled-system/css';

export const elementWithDisplayOptions = cva({
	base: {
		maxWidth: '100%',
		flexBasis: '100%',
		flexGrow: '0',
		flexShrink: '0',
	},
	variants: {
		displayOption: {
			quarterWidth: {
				flexBasis: {
					base: '100%',
					md: 'calc((100% - (var(--display-options-spacing-md) * 3)) / 4)',
					lg: 'calc((100% - (var(--display-options-spacing-lg) * 3)) / 4)',
				},
			},
			thirdWidth: {
				flexBasis: {
					base: '100%',
					md: 'calc((100% - (var(--display-options-spacing-md) * 2)) / 3)',
					lg: 'calc((100% - (var(--display-options-spacing-lg) * 2)) / 3)',
				},
			},
			halfWidth: {
				flexBasis: {
					base: '100%',
					md: 'calc((100% - var(--display-options-spacing-md))/2)',
					lg: 'calc((100% - var(--display-options-spacing-lg))/2)',
				},
			},
			twothirdWidth: {
				flexBasis: {
					base: '100%',
					md: 'calc((100% - (var(--display-options-spacing-md) * 2)) / 1.5)',
					lg: 'calc((100% - (var(--display-options-spacing-lg) * 2)) / 1.5)',
				},
			},
			fullWidth: {
				flexBasis: '100%',
			},
			hideBelowMd: {
				hideBelow: 'md',
			},
			hideBelowLg: {
				hideBelow: 'lg',
			},
			hideFromMd: {
				hideFrom: 'md',
			},
			hideFromLg: {
				hideFrom: 'lg',
			},
		},
	},
});
