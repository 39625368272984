'use client';

import { Button } from '@/components/core/Button/Button';
import useQueryParams from '@/hooks/useQueryParams';
import { CloseIcon } from '@/icons/CloseIcon';
import { SearchIcon } from '@/icons/SearchIcon';
import { sva } from '@/styled-system/css';
import { Box, BoxProps } from '@/styled-system/jsx';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';
import { FC, KeyboardEvent, useRef, useState } from 'react';

interface IContentSearchFieldProps extends BoxProps {
	contentSearchTranslations: TranslationLabelValues;
}

export const ContentSearchField: FC<IContentSearchFieldProps> = ({ contentSearchTranslations, ...rest }) => {
	const classes = contentSearchField();
	const inputRef = useRef<HTMLInputElement>(null);

	const { query, change } = useQueryParams();
	const [value, setValue] = useState(initializeInputValue(query.s));
	const [isClearButtonVisible, setIsClearButtonVisible] = useState(false);
	const buttonLabel = translate(contentSearchTranslations, 'contentSearch.input.buttonLabel');

	const onBlur = () => {
		if (!value) {
			setIsClearButtonVisible(false);
		}
	};

	const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			change({ ...query, s: value });
		}
	};

	const clearInput = () => {
		if (value) {
			setValue('');
			change({ ...query, s: '' });

			inputRef.current?.focus();
		}
	};

	return (
		<Box data-part="root" className={classes.root} {...rest}>
			<Box data-part="leftElement" className={classes.leftElement}>
				<SearchIcon />
			</Box>

			<input
				ref={inputRef}
				data-part="input"
				className={classes.input}
				value={value}
				onBlur={onBlur}
				onKeyUp={onKeyUp}
				placeholder={translate(contentSearchTranslations, 'contentSearch.input.placeholder')}
				onChange={(e) => setValue(e.target.value)}
				onFocus={() => setIsClearButtonVisible(true)}
			/>

			<Box data-part="rightElement" className={classes.rightElement}>
				{isClearButtonVisible && (
					<button data-part="clearButton" className={classes.clearButton} onClick={clearInput}>
						<CloseIcon />
					</button>
				)}

				<Button
					size="large"
					className={classes.searchButton}
					title={buttonLabel}
					aria-label={buttonLabel}
					onClick={() => change({ ...query, s: value })}
				>
					<SearchIcon />
					<span>{buttonLabel}</span>
				</Button>
			</Box>
		</Box>
	);
};

const initializeInputValue = (query?: string | string[]): string => {
	if (!query) {
		return '';
	}

	if (Array.isArray(query)) {
		return query.join(',');
	}

	return query;
};

const contentSearchField = sva({
	slots: ['root', 'input', 'leftElement', 'rightElement', 'clearButton', 'searchButton'],
	base: {
		root: {
			width: 'full',
			display: 'flex',
			position: 'relative',
			isolation: 'isolate',
		},
		input: {
			width: 'full',
			height: '20',
			outline: 'transparent solid 2px',
			outlineOffset: '2px',
			base: {
				pl: '6',
				pr: '125px',
			},
			md: {
				pl: '14',
				pr: '250px',
			},
			position: 'relative',
			appearance: 'none',
			boxShadow: 'subtleBottom',
			borderRadius: '3xl',

			transition: 'background-color 0.3s ease-out',
			backgroundColor: 'surface.interactive.card.primary',
			_hover: {
				backgroundColor: 'surface.interactive.input.regular.hoverAndPressed',
			},

			color: 'text.regular.default',
			_placeholder: {
				color: 'text.regular.subtitle',
			},
		},
		leftElement: {
			display: {
				base: 'none',
				md: 'flex',
			},
			left: '0px',
			width: '14',
			height: '20',
			alignItems: 'center',
			justifyContent: 'end',
			position: 'absolute',
			top: '0px',
			zIndex: '2',
			pointerEvents: 'none',
			color: 'text.regular.default',

			'& > svg': {
				width: '24px',
				height: '24px',
				marginRight: '8px',
			},
		},

		rightElement: {
			right: '0px',
			width: {
				base: '125px',
				md: '250px',
			},
			height: '20',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'end',
			position: 'absolute',
			top: '0px',
			zIndex: '2',
			color: 'text.regular.default',
		},

		clearButton: {
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '48px',
			height: '48px',

			'& > svg': {
				width: '32px',
				height: '32px',
			},
		},
		searchButton: {
			mr: '4',

			base: {
				w: 'max-content',

				'& > svg': {
					display: 'block',
					width: '24px',
					height: '24px',
				},

				'& > span': {
					display: 'none',
				},
			},

			md: {
				w: '185px',

				'& > svg': {
					display: 'none',
				},

				'& > span': {
					display: 'inline',
				},
			},
		},
	},
});
