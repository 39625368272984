import { themeContract } from './contract';

export const defaultTheme = themeContract({
	semanticTokens: {
		fonts: {
			heading: {
				h1: {
					value: '{fonts.serif}',
				},
			},
		},
		colors: {
			text: {
				regular: {
					default: {
						value: '{colors.base.neutrals.90}',
					},
					subtitle: {
						value: '{colors.base.neutrals.70}',
					},
					disabled: {
						value: '{colors.base.neutrals.100/30}',
					},
					pressed: {
						value: '{colors.base.neutrals.100}',
					},
					danger: {
						value: '{colors.signal.red.60}',
					},
					success: {
						value: '{colors.signal.green.60}',
					},
				},
				inverted: {
					default: {
						value: '{colors.base.neutrals.0}',
					},
					subtitle: {
						value: '{colors.base.neutrals.40}',
					},
					disabled: {
						value: '{colors.base.neutrals.0/30}',
					},
					pressed: {
						value: '{colors.base.neutrals.30}',
					},
					danger: {
						value: '{colors.signal.red.50}',
					},
					success: {
						value: '{colors.signal.green.50}',
					},
				},
			},
			icons: {
				regular: {
					default: {
						value: '{colors.base.neutrals.90}',
					},
					secondary: {
						value: '{colors.base.neutrals.70}',
					},
					disabled: {
						value: '{colors.base.neutrals.100/30}',
					},
					pressed: {
						value: '{colors.base.neutrals.100}',
					},
					danger: {
						value: '{colors.signal.red.60}',
					},
				},
				inverted: {
					default: {
						value: '{colors.base.neutrals.0}',
					},
					secondary: {
						value: '{colors.base.neutrals.40}',
					},
					disabled: {
						value: '{colors.base.neutrals.0/30}',
					},
					pressed: {
						value: '{colors.base.neutrals.30}',
					},
					danger: {
						value: '{colors.signal.red.50}',
					},
				},
				accent: {
					aquamarine: {
						value: '{colors.evolution.aquamarine.40}',
					},
					coral: {
						value: '{colors.evolution.coral.40}',
					},
					citrine: {
						value: '{colors.evolution.citrine.40}',
					},
					sand: {
						value: '{colors.base.sand.40}',
					},
				},
			},
			surface: {
				static: {
					primary: {
						value: '{colors.base.neutrals.0}',
					},
					secondary: {
						value: '{colors.base.sand.30}',
					},
					tertiary: {
						value: '{colors.base.neutrals.30}',
					},
					chat: {
						value: '{colors.base.neutrals.20}',
					},
					inverted: {
						value: '{colors.base.neutrals.90}',
					},
					primaryInverted: {
						value: '{colors.base.sand.90}',
					},
					invertedException: {
						value: '{colors.base.neutrals.100}',
					},
					aquamarine: {
						value: '{colors.evolution.aquamarine.40}',
					},
					citrine: {
						value: '{colors.evolution.citrine.40}',
					},
					coral: {
						value: '{colors.evolution.coral.40}',
					},
				},
				interactive: {
					card: {
						primary: {
							value: '{colors.base.neutrals.0}',
						},
						secondary: {
							value: '{colors.base.sand.30}',
						},
						inverted: {
							value: '{colors.base.neutrals.90}',
						},
						primaryInverted: {
							value: '{colors.base.neutrals.90}',
						},
						primaryAccent: {
							value: '{colors.evolution.aquamarine.40}',
						},
						secondaryAccent: {
							value: '{colors.evolution.citrine.40}',
						},
						tertiaryAccent: {
							value: '{colors.base.sand.30}',
						},
					},
					transparent: {
						regular: {
							default: {
								value: '{colors.base.neutrals.0}',
							},
							hover: {
								value: '{colors.base.sand.20}',
							},
							pressed: {
								value: '{colors.base.sand.30}',
							},
							disabled: {
								value: '{colors.base.neutrals.20}',
							},
						},
						inverted: {
							default: {
								value: '{colors.base.neutrals.90}',
							},
							hover: {
								value: '{colors.base.neutrals.100/30}',
							},
							pressed: {
								value: '{colors.base.neutrals.100/30}',
							},
							disabled: {
								value: '{colors.base.neutrals.80}',
							},
						},
					},
					input: {
						regular: {
							hoverAndPressed: {
								value: '{colors.base.neutrals.10}',
							},
						},
						inverted: {
							hoverAndPressed: {
								value: '{colors.base.neutrals.100/30}',
							},
						},
					},
					accordion: {
						regular: {
							fillDefault: {
								value: '{colors.base.neutrals.0}',
							},
							fillDefaultOpened: {
								value: '{colors.base.sand.30}',
							},
							fillHover: {
								value: '{colors.base.sand.30}',
							},
							fillHoverOpened: {
								value: '{colors.base.sand.40}',
							},
						},
						inverted: {
							fillDefault: {
								value: '{colors.base.neutrals.90}',
							},
							fillDefaultOpened: {
								value: '{colors.base.neutrals.0/8}',
							},
							fillHover: {
								value: '{colors.base.neutrals.0/8}',
							},
							fillHoverOpened: {
								value: '{colors.base.neutrals.0/30}',
							},
						},
						sand: {
							fillDefault: {
								value: '{colors.base.sand.30}',
							},
							fillDefaultOpened: {
								value: '{colors.base.sand.40}',
							},
							fillHover: {
								value: '{colors.base.sand.40}',
							},
							fillHoverOpened: {
								value: '{colors.base.sand.50}',
							},
						},
					},
					skeleton: {
						regular: {
							value: '{colors.base.neutrals.100/8}',
						},
						inverted: {
							value: '{colors.base.neutrals.0/8}',
						},
					},
				},
			},
			actions: {
				primary: {
					regular: {
						fillDefault: {
							value: '{colors.base.neutrals.90}',
						},
						textAndIconDefault: {
							value: '{colors.base.neutrals.0}',
						},
						fillHover: {
							value: '{colors.base.neutrals.100}',
						},
						fillPressed: {
							value: '{colors.base.neutrals.100}',
						},
						textAndIconPressed: {
							value: '{colors.base.neutrals.60}',
						},
						fillDisabled: {
							value: '{colors.base.neutrals.90/30}',
						},
					},
					inverted: {
						fillDefault: {
							value: '{colors.base.neutrals.0}',
						},
						textAndIconDefault: {
							value: '{colors.base.neutrals.90}',
						},
						fillHover: {
							value: '{colors.base.neutrals.30}',
						},
						fillPressed: {
							value: '{colors.base.neutrals.40}',
						},
						textAndIconPressed: {
							value: '{colors.base.neutrals.90}',
						},
						fillDisabled: {
							value: '{colors.base.neutrals.0/30}',
						},
					},
				},
				primaryDanger: {
					regular: {
						fillDefault: {
							value: '{colors.signal.red.60}',
						},
						fillHoverAndPressed: {
							value: '{colors.signal.red.70}',
						},
						fillDisabled: {
							value: '{colors.base.neutrals.90/30}',
						},
					},
					inverted: {
						fillDefault: {
							value: '{colors.signal.red.60}',
						},
						fillHoverAndPressed: {
							value: '{colors.signal.red.70}',
						},
						fillDisabled: {
							value: '{colors.base.neutrals.0/30}',
						},
					},
				},
				secondary: {
					regular: {
						strokeDefault: {
							value: '{colors.base.neutrals.90}',
						},
						fillHover: {
							value: '{colors.base.neutrals.100/8}',
						},
						fillPressed: {
							value: '{colors.base.neutrals.100/12}',
						},
						strokeDisabled: {
							value: '{colors.base.neutrals.100/30}',
						},
					},
					inverted: {
						default: {
							value: '{colors.base.neutrals.0}',
						},
						fillHover: {
							value: '{colors.base.neutrals.0/8}',
						},
						fillPressed: {
							value: '{colors.base.neutrals.0/8}',
						},
						strokeDisabled: {
							value: '{colors.base.neutrals.0/30}',
						},
					},
				},
				secondaryDanger: {
					regular: {
						strokeDefault: {
							value: '{colors.signal.red.60}',
						},
						fillHover: {
							value: '{colors.signal.red.10}',
						},
						fillPressed: {
							value: '{colors.signal.red.20}',
						},
						strokeDisabled: {
							value: '{colors.base.neutrals.90/30}',
						},
					},
					inverted: {
						strokeDefault: {
							value: '{colors.signal.red.50}',
						},
						fillHover: {
							value: '{colors.base.neutrals.100/30}',
						},
						fillPressed: {
							value: '{colors.base.neutrals.100/30}',
						},
						strokeDisabled: {
							value: '{colors.base.neutrals.0/30}',
						},
					},
				},
				tertiary: {
					regular: {
						fillDefault: {
							value: '{colors.base.sand.30}',
						},
						fillHover: {
							value: '{colors.base.sand.40}',
						},
						fillPressed: {
							value: '{colors.base.sand.50}',
						},
						fillDisabled: {
							value: '{colors.base.sand.20}',
						},
					},
					inverted: {
						fillDefault: {
							value: '{colors.base.sand.70}',
						},
						fillHover: {
							value: '{colors.base.sand.80}',
						},
						fillPressed: {
							value: '{colors.base.sand.90}',
						},
						fillDisabled: {
							value: '{colors.base.sand.100}',
						},
					},
				},
				links: {
					regular: {
						default: {
							value: '{colors.secondary.nucleusBlue.60}',
						},
						hoverAndPressed: {
							value: '{colors.secondary.nucleusBlue.70}',
						},
						visited: {
							value: '{colors.secondary.nucleusBlue.60}',
						},
						disabled: {
							value: '{colors.secondary.nucleusBlue.30}',
						},
					},
					inverted: {
						default: {
							value: '{colors.secondary.nucleusBlue.30}',
						},
						hoverAndPressed: {
							value: '{colors.secondary.nucleusBlue.20}',
						},
						visited: {
							value: '{colors.secondary.nucleusBlue.30}',
						},
					},
				},
				controls: {
					regular: {
						onFillDefault: {
							value: '{colors.secondary.nucleusBlue.60}',
						},
						onFillHoverAndPressed: {
							value: '{colors.secondary.nucleusBlue.70}',
						},
						onStrokeFocus: {
							value: '{colors.secondary.nucleusBlue.10}',
						},
						onFillDisabled: {
							value: '{colors.secondary.nucleusBlue.20}',
						},
						offFillDefault: {
							value: '{colors.base.neutrals.100/30}',
						},
						offFillHoverAndPressed: {
							value: '{colors.base.neutrals.100/50}',
						},
						offFillDisabled: {
							value: '{colors.base.neutrals.100/8}',
						},
						fillThumb: {
							value: '{colors.base.neutrals.0}',
						},
					},
					inverted: {
						onFillDefault: {
							value: '{colors.secondary.nucleusBlue.30}',
						},
						onFillHoverAndPressed: {
							value: '{colors.secondary.nucleusBlue.20}',
						},
						onStrokeFocus: {
							value: '{colors.secondary.nucleusBlue.60}',
						},
						onFillDisabled: {
							value: '{colors.secondary.nucleusBlue.60}',
						},
						offFillDefault: {
							value: '{colors.base.neutrals.0/30}',
						},
						offFillHoverAndPressed: {
							value: '{colors.base.neutrals.0/50}',
						},
						offFillDisabled: {
							value: '{colors.base.neutrals.0/8}',
						},
						fillThumb: {
							value: '{colors.base.neutrals.0}',
						},
					},
				},
				controlsDanger: {
					regular: {
						onFillDefault: {
							value: '{colors.signal.red.60}',
						},
						onFillHoverAndPressed: {
							value: '{colors.signal.red.70}',
						},
						onStrokeFocus: {
							value: '{colors.signal.red.20}',
						},
						onFillDisabled: {
							value: '{colors.signal.red.20}',
						},
					},
					inverted: {
						onFillDefault: {
							value: '{colors.signal.red.50}',
						},
						onFillHoverAndPressed: {
							value: '{colors.signal.red.60}',
						},
						onStrokeFocus: {
							value: '{colors.signal.red.50/30}',
						},
						onFillDisabled: {
							value: '{colors.signal.red.50/30}',
						},
					},
				},
				filterTags: {
					regular: {
						fillDefault: {
							value: '{colors.base.neutrals.0}',
						},
						strokeHover: {
							value: '{colors.secondary.nucleusBlue.60}',
						},
						fillSelected: {
							value: '{colors.secondary.nucleusBlue.60}',
						},
						fillSelectedHoverAndPressed: {
							value: '{colors.secondary.nucleusBlue.70}',
						},
						fillDisabled: {
							value: '{colors.secondary.nucleusBlue.20}',
						},
						textAndIconDisabled: {
							value: '{colors.base.neutrals.100/30}',
						},
					},
					inverted: {
						fillDefault: {
							value: '{colors.base.neutrals.90}',
						},
						strokeHover: {
							value: '{colors.secondary.nucleusBlue.30}',
						},
						fillSelected: {
							value: '{colors.secondary.nucleusBlue.30}',
						},
						fillSelectedHoverAndPressed: {
							value: '{colors.secondary.nucleusBlue.20}',
						},
						fillDisabled: {
							value: '{colors.secondary.nucleusBlue.10}',
						},
						textAndIconDisabled: {
							value: '{colors.base.neutrals.100/30}',
						},
					},
				},
				uploads: {
					fillHover: {
						value: '{colors.secondary.nucleusBlue.10/30}',
					},
					strokeHover: {
						value: '{colors.secondary.nucleusBlue.60}',
					},
				},
			},
			other: {
				scrim: {
					value: '{colors.base.neutrals.90/80}',
				},
				indicator: {
					regular: {
						hover: {
							value: '{colors.base.sand.80}',
						},
						selected: {
							value: '{colors.secondary.nucleusBlue.60}',
						},
						default: {
							value: '{colors.base.neutrals.90/30}',
						},
					},
					inverted: {
						hover: {
							value: '{colors.base.neutrals.80}',
						},
						selected: {
							value: '{colors.secondary.nucleusBlue.30}',
						},
					},
				},
				border: {
					regular: {
						default: {
							value: '{colors.base.neutrals.90/20}',
						},
						strong: {
							value: '{colors.base.neutrals.80}',
						},
						weak: {
							value: '{colors.base.neutrals.20}',
						},
						focus: {
							value: '{colors.base.neutrals.90}',
						},
						disabled: {
							value: '{colors.base.neutrals.20}',
						},
					},
					inverted: {
						default: {
							value: '{colors.base.neutrals.0/15}',
						},
						focus: {
							value: '{colors.base.neutrals.0}',
						},
					},
					sand: {
						hairline: {
							value: '{colors.base.sand.40}',
						},
						weak: {
							value: '{colors.base.sand.70}',
						},
						strong: {
							value: '{colors.base.sand.90}',
						},
					},
				},
				pattern: {
					aquamarine: { value: '{colors.evolution.aquamarine.40}' },
					citrine: { value: '{colors.evolution.citrine.40}' },
					coral: { value: '{colors.evolution.coral.40}' },
					dark: { value: '{colors.base.neutrals.90}' },
					light: { value: '{colors.base.neutrals.0}' },
				},
			},
			trafficLights: {
				success: {
					onBackground: {
						value: '{colors.signal.green.70}',
					},
					default: {
						value: '{colors.signal.green.60}',
					},
					variant: {
						value: '{colors.signal.green.50}',
					},
					background: {
						value: '{colors.signal.green.20}',
					},
				},
				warning: {
					default: {
						value: '{colors.signal.yellow.50}',
					},
					background: {
						value: '{colors.signal.yellow.20}',
					},
				},
				error: {
					onBackground: {
						value: '{colors.signal.red.70}',
					},
					default: {
						value: '{colors.signal.red.60}',
					},
					variant: {
						value: '{colors.signal.red.50}',
					},
					background: {
						value: '{colors.signal.red.20}',
					},
					backgroundVariant: {
						value: '{colors.signal.red.10}',
					},
				},
			},
			signal: {
				background: {
					regular: {
						success: {
							value: '{colors.signal.green.20}',
						},
						warning: {
							value: '{colors.signal.yellow.20}',
						},
						danger: {
							value: '{colors.signal.red.20}',
						},
						neutral: {
							value: '{colors.base.neutrals.30}',
						},
						information: {
							value: '{colors.base.sand.40}',
						},
					},
					inverted: {
						success: {
							value: '{colors.signal.green.20}',
						},
						warning: {
							value: '{colors.signal.yellow.20}',
						},
						danger: {
							value: '{colors.signal.red.20}',
						},
						neutral: {
							value: '{colors.base.neutrals.30}',
						},
						brand: {
							value: '{colors.base.sand.70}',
						},
					},
				},
				onBackground: {
					regular: {
						success: {
							value: '{colors.signal.green.70}',
						},
						warning: {
							value: '{colors.base.neutrals.90}',
						},
						danger: {
							value: '{colors.signal.red.70}',
						},
						neutral: {
							value: '{colors.base.neutrals.90}',
						},
						brand: {
							value: '{colors.base.neutrals.90}',
						},
					},
					inverted: {
						success: {
							value: '{colors.signal.green.70}',
						},
						warning: {
							value: '{colors.base.neutrals.90}',
						},
						danger: {
							value: '{colors.signal.red.70}',
						},
						neutral: {
							value: '{colors.base.neutrals.90}',
						},
						brand: {
							value: '{colors.base.neutrals.0}',
						},
					},
				},
			},
		},
	},
});
