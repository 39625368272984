import { IContentResponse, IBlockContent, IBlockResponse } from '@/interfaces/coreInformation';
import { DisplayOption } from '@/types/display-options';

export enum TextFormFieldDisplayMode {
	TEXT = 'Text',
	LONGTEXT = 'LongText',
	EMAIL = 'Email',
	PHONE_NUMBER = 'PhoneNumber',
}

export enum SelectFormFieldDisplayMode {
	CHECKBOX_1_ROW = 'Checkbox1perRow',
	RADIO_BUTTONS = 'RadioButtons',
	DROPDOWN_SINGLE = 'DropdownSingleSelect',
	DROPDOWN_MULTI = 'DropdownMultiSelect',
}

export enum AutoCollectData {
	PageUrl = 'PageUrl',
	PageCulture = 'PageCulture',
	PageCountry = 'PageCountry',
	PageLanguage = 'PageLanguage',
	ClientData = 'ClientData',
	GoogleClickID = 'GoogleClickID',
}

export type FormFieldDisplayMode = TextFormFieldDisplayMode | SelectFormFieldDisplayMode;

export interface IFormFieldResponse extends IContentResponse<IFormFieldContent> {
	displayOption: DisplayOption;
}

export interface IFormFieldContent extends IBlockContent {
	displayMode?: FormFieldDisplayMode;
	fieldLabel?: string;
	predefinedValue?: string;
	mandatoryErrorMessage?: string;
	fieldName?: string;
	isMandatory?: boolean;
	isHidden?: boolean;
	preselectFromUrlParameters: boolean;
	overwriteWithDefaultOnLoad?: boolean;
	saveAsContactNote?: boolean;
	makeReadOnlyIfHasInitialValue?: boolean;
	triggersPersonalization?: boolean;
	autoCollectData?: AutoCollectData;
}

export interface IFormFieldFileUploadResponse extends IBlockResponse<IFormFieldFileUploadContent> {}

export interface IFormFieldFileUploadContent extends IFormFieldContent {
	mandatoryErrorMessage: string;
	fieldName: string;
	fieldDescription: string;
	allowCapturePhotosAndVideos: string;
	maximumFileSize: number;
	maximumNumberOfFiles: number;
	allowedTotalSizeOnMultiple: number;
	isMandatory: boolean;
	placeholderText?: string;
	allowedFileTypes?: string;
	allowedFilesInfoText?: string;
}

export interface ITextFormFieldResponse extends IBlockResponse<ITextFormFieldContent> {}

export interface ITextFormFieldContent extends IFormFieldContent {
	displayMode: TextFormFieldDisplayMode;
	textMaxLength?: number;
	placeholderText?: string;
}

export interface ISelectFormFieldContent extends IFormFieldContent {
	displayMode: SelectFormFieldDisplayMode;
	selectionItems?: Array<ISelectionItemResponse>;
	valueSelectPrompt?: string;
}

export interface ISelectFormFieldResponse extends IBlockResponse<ISelectFormFieldContent> {}

export interface ISelectionItemContent extends IBlockContent {
	textOrLabel: string;
	value: string;
	isHidden?: boolean;
	isInitiallyChecked?: boolean;
	preselectFromUrlParameters: boolean;
	saveAsContactNote?: boolean;
	dataType?: string;
}

export interface ISelectionItemResponse extends IBlockResponse<ISelectionItemContent> {}
