'use client';

import { createContext, useContext } from 'react';

export interface ITealiumInstance {
	isTealiumReady: boolean;
	utag: typeof globalThis.utag;
}

export const TealiumInstanceContext = createContext<ITealiumInstance>({
	isTealiumReady: false,
	utag: undefined,
});

export const useTealiumInstance = () => {
	return useContext(TealiumInstanceContext);
};
