import {
	FormFieldServerComponent,
	FormServerComponents,
	IFormContainerBlockContent,
} from '@/components/block-templates/Forms/FormContainerBlockTemplate/FormContainerBlockTemplate';
import {
	isFormUploadField,
	isSelectFormField,
	isTextFormField,
} from '@/components/block-templates/Forms/FormContainerBlockTemplate/FormContainerBlockTemplate.utils';
import { ElementWithDisplayOptionsWrapper } from '@/components/shared/ElementWithDisplayOptions/ElementWithDisplayOptions.utils';
import { FormFieldUpload } from '@/components/shared/Forms/FileUploadFormField';
import { SelectFormField } from '@/components/shared/Forms/SelectFormField';
import { TextFormField } from '@/components/shared/Forms/TextFormField';
import { IVontobelProfile } from '@/interfaces/VontobelProfile';
import {
	IFormFieldFileUploadResponse,
	IFormFieldResponse,
	ISelectFormFieldResponse,
	ITextFormFieldResponse,
} from '@/interfaces/blocks/forms';
import { IPageData } from '@/interfaces/coreInformation';
import { IFormSubmitDetails } from '@/types/form-submission/IFormSubmitBody';
import { TranslationLabelValues } from '@/utils/i18n/translation-labels/translationLabels';
import { ReactNode } from 'react';
import sanitizeHtml from 'sanitize-html';

export interface IFormResponse {
	result: boolean;
	hashId?: string | null;
}

export enum FormType {
	FormSalesforce = 'FormSalesforce',
	FormEmail = 'FormEmail',
	FormAtlassian = 'FormAtlassian',
}

export interface IFormProps {
	formType: FormType;
	formContainer: IFormContainerBlockContent;
	pageData: IPageData;
	serverFormFields: FormServerComponents;
	translations: TranslationLabelValues;
	vontobelProfile?: IVontobelProfile;
}

function generateFieldPersonalizationPayload(
	fieldName: string,
	value: string | Array<string>
): Array<{ fieldName: string; fieldValue: string }> {
	if (Array.isArray(value)) {
		return value.map((item) => {
			return {
				fieldName: fieldName,
				fieldValue: item,
			};
		});
	} else {
		return [
			{
				fieldName: fieldName,
				fieldValue: value,
			},
		];
	}
}

export async function saveFormPersonalizationValues(
	values: Array<{ fieldName: string; value: string | Array<string> }>
) {
	const personalizationPayload = {
		formPersonalizationSubmitItems: values.flatMap((item) => {
			return generateFieldPersonalizationPayload(item.fieldName, item.value);
		}),
	};

	const response = await fetch(`/api/FormContainer/Form/Personalization/`, {
		body: JSON.stringify(personalizationPayload),
		method: 'POST',
	});

	if (response.ok) {
		const responseBody = await response.json();

		return responseBody.valueChanged;
	}

	return false;
}

// Form needs to render both server and client components as children. Client components also need to be rerendered when the form is updated (client-side)
// We do that by passing server components as props and rendering client components on the client. This function renders the client components and merges them with server components to keep order.
export function mergeClientAndServerFormComponents(
	// eslint-disable-next-line @typescript-eslint/ban-types
	formPersonalizationHandler: Function,
	translations: TranslationLabelValues,
	serverFormComponents: Array<FormFieldServerComponent> = [],
	formFields: Array<IFormFieldResponse> = []
) {
	return formFields.map((field) => {
		let clientField: ReactNode | undefined = undefined;

		if (isTextFormField(field)) {
			clientField = (
				<TextFormField
					field={field as ITextFormFieldResponse}
					translations={translations}
					formPersonalizationHandler={
						field.contentLink.expanded.triggersPersonalization ? formPersonalizationHandler : undefined
					}
				/>
			);
		}

		if (isSelectFormField(field)) {
			clientField = (
				<SelectFormField
					field={field as ISelectFormFieldResponse}
					translations={translations}
					formPersonalizationHandler={
						field.contentLink.expanded.triggersPersonalization ? formPersonalizationHandler : undefined
					}
				/>
			);
		}

		if (isFormUploadField(field)) {
			clientField = <FormFieldUpload field={field as IFormFieldFileUploadResponse} translations={translations} />;
		}

		const resultingField: ReactNode | FormFieldServerComponent | undefined =
			clientField || serverFormComponents.find((component) => component.id === field.contentLink.id)?.component;

		return resultingField ? (
			<ElementWithDisplayOptionsWrapper displayOption={field.displayOption} key={field?.contentLink?.id}>
				{resultingField}
			</ElementWithDisplayOptionsWrapper>
		) : null;
	});
}

export function splitFormFieldsAndAttachments(formFields: Array<IFormFieldResponse>, formData: any) {
	const fileAttachments: Record<string, Array<File>> = {};
	const formFieldsData: Record<string, any> = {};

	Object.keys(formData).forEach((key) => {
		const currentField = formFields.find((field) => field?.contentLink?.expanded?.fieldName === key);

		if (isFormUploadField(currentField)) {
			fileAttachments[key] = formData[key];
		} else {
			formFieldsData[key] = formData[key];
		}
	});

	return {
		fileAttachments,
		formFieldsData,
	};
}

export function constructFormData(
	formDetails: IFormSubmitDetails,
	fileAttachments: Record<string, Array<File>>
): FormData {
	const formData = new FormData();

	formData.append('formDetails', JSON.stringify(formDetails));

	for (const key of Object.keys(fileAttachments)) {
		for (const file of fileAttachments[key]) {
			formData.append('attachments', file);
		}
	}

	return formData;
}

export function sanitizeInputs(inputs: Record<string, string>): Record<string, string> {
	const sanitized: Record<string, string> = {};

	for (const key in inputs) {
		sanitized[key] = sanitizeHtml(inputs[key], {
			allowedTags: [],
			allowedAttributes: {},
		});
	}

	return sanitized;
}
