import { FC, SVGAttributes } from 'react';

export const DocumentDOCIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor" {...props}>
			<path
				d="M25.3968 4.06348H6.60315V27.9365H25.3968V4.06348ZM8.12696 26.4127V5.58729H23.873V26.4127H8.12696Z"
				fill="currentColor"
			/>
			<path
				d="M11.4083 13.7041H10.0623V17.7778H11.3118C12.7797 17.7778 13.4857 16.6908 13.4857 15.6597C13.4857 14.6285 12.8 13.7041 11.4083 13.7041ZM11.3219 17.0666H10.814V14.4101H11.4337C12.3124 14.4101 12.7238 15.0146 12.7238 15.6597C12.7238 16.3505 12.2616 17.0666 11.3219 17.0666Z"
				fill="currentColor"
			/>
			<path
				d="M15.7968 13.6074C14.5727 13.6074 13.7549 14.5674 13.7549 15.7357C13.7549 16.9039 14.5879 17.8842 15.8069 17.8842C17.026 17.8842 17.8336 16.9039 17.8336 15.7509C17.8336 14.5979 17.026 13.6125 15.7968 13.6125V13.6074ZM15.7968 17.1528C15.0044 17.1528 14.5219 16.5433 14.5219 15.7509C14.5219 14.9585 15.0146 14.3338 15.8069 14.3338C16.5993 14.3338 17.0666 14.9433 17.0666 15.7357C17.0666 16.5281 16.5892 17.1528 15.7968 17.1528Z"
				fill="currentColor"
			/>
			<path
				d="M20.099 17.1582C19.2965 17.1582 18.8698 16.4674 18.8698 15.7106C18.8698 14.9537 19.2558 14.3188 20.0889 14.3188C20.6273 14.3188 21.0641 14.6033 21.1098 15.0655H21.8819C21.7803 14.1309 21.0692 13.6128 20.1041 13.6128C18.7428 13.6128 18.1079 14.6541 18.1079 15.7258C18.1079 16.8483 18.7987 17.8845 20.0889 17.8845C21.0133 17.8845 21.8362 17.3563 21.8971 16.2795H21.1251C21.0743 16.9093 20.5714 17.1531 20.0939 17.1531L20.099 17.1582Z"
				fill="currentColor"
			/>
		</svg>
	);
};
