import { siteNameToThemeMap } from '@/styles/theme/themes';
import { SiteName } from '@/types/feature-flags';

export const apiEndpoint = process.env.NEXT_PUBLIC_API_ENDPOINT;

export const cmsHost = process.env.CMS_HOST;

export const cmsApiEndpoint = `${cmsHost}${process.env.CMS_API_PATH}`;

export const cmsContentEndpoint = `${cmsApiEndpoint}content/`;

export const googleCustomSearchEndpoint = 'https://www.googleapis.com/customsearch/v1';

export const publicDomainUrl = process.env.NEXT_PUBLIC_DOMAIN_URL;

export const vimeoUrlBase = 'https://player.vimeo.com/video';

export const vimeoApiUrlBase = 'https://vimeo.com/api/oembed.json?url=https://vimeo.com';

export const webApiHost = process.env.WEBAPI_HOST;

export const webApiVersion = process.env.WEBAPI_VERSION;

export const siteName = (process.env.SITE_NAME || 'corpweb') as SiteName;

export const page404Id = process.env.NEXT_PUBLIC_404_PAGE_ID;

export const themeName = siteNameToThemeMap[siteName];

/**
 * Use with care. Do not pass this prop to clientside code!
 */
export const webApiToken = process.env.WEBAPI_TOKEN;

export const googleSearchApiKey = process.env.GOOGLE_SEARCH_API_KEY;

export const salesforceApiEndpoint = webApiHost + '/' + process.env.WEBAPI_SALESFORCE_PATH;

export const seoSettingsUrl = `${cmsHost}/api/Settings/GetSEOSettings`;

export const getArticlesUrl = `${cmsHost}/api/article/GetArticles`;

export const trackingSettingsUrl = `${cmsHost}/api/Settings/GetTrackingSettings`;

export const httpSecuritySettingsUrl = `${cmsHost}/api/settings/GetHttpSecuritySettings`;

/**
 * SMTP settings
 */
export const smtpHost = process.env.SMTP_HOST;

export const smtpServerName = process.env.SMTP_SERVER_NAME;

export const smtpPort = Number(process.env.SMTP_PORT);

export const smtpSecure = process.env.SMTP_SECURE === 'true';
