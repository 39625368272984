import { styled } from '@/styled-system/jsx';
import { poly } from '@/libs/poly';

export const Heading = (props: any) => {
	return <HeadingRecipe {...props} as={props.as} type={props.type || props.as} />;
};

export const HeadingRecipe = styled(poly('h2'), {
	base: {
		fontWeight: 'bold',
		color: 'inherit',
		wordBreak: 'break-word',
	},
	defaultVariants: {
		type: 'h2',
	},
	variants: {
		type: {
			h1: {
				// ex: 2xl
				// H1: 40px 52px 68px
				fontSize: { base: '2.5rem', md: '3.25rem', lg: '4.25rem' },
				letterSpacing: '-1%',
				lineHeight: '115%',
				fontFamily: 'heading.h1',
			},
			h2: {
				// ex: xl
				// H2: 32px 40px 48px
				fontSize: { base: '2rem', md: '2.5rem', xl: '3rem' },
				letterSpacing: '-1%',
				lineHeight: '120%',
			},
			h3: {
				// ex: lg
				// H3: 28px 36px 40px
				fontSize: { base: '1.75rem', md: '2.25rem', lg: '2.5rem' },
				lineHeight: '120%',
				letterSpacing: '0%',
			},
			h4: {
				// ex: md
				// H4: 24px 28px 32px
				fontSize: { base: '1.5rem', md: '1.75rem', lg: '2rem' },
				lineHeight: '125%',
				letterSpacing: '0%',
			},
			h5: {
				// ex: sm
				// H5: 20px 24px
				fontSize: { base: '1.25rem', md: '1.5rem' },
				lineHeight: '130%',
				letterSpacing: '0%',
			},
			h6: {
				// ex: xs
				// H6: 18px 20px
				fontSize: { base: '1.125rem', md: '1.25rem' },
				lineHeight: '130%',
				letterSpacing: '-1%',
				fontWeight: 'semibold',
			},
		},
	},
});
