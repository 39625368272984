import { styled } from '@/styled-system/jsx';
import { poly } from '@/libs/poly';

export const Text = styled(poly('p'), {
	base: {
		color: 'inherit',
		textAlign: 'left',
	},
	defaultVariants: {
		size: 'lg',
	},
	variants: {
		size: {
			lg: {
				// Body 1: 18px
				fontSize: '1.125rem',
				lineHeight: '150%',
			},
			md: {
				// Body 2: 16px
				fontSize: '1rem',
				lineHeight: '150%',
			},
			sm: {
				// Body 3: 14px
				fontSize: '0.875rem',
				fontWeight: '400',
				lineHeight: '146%',
			},
			xs: {
				// Caption: 12px
				fontSize: '0.75rem',
				letterSpacing: '0.24px',
				fontWeight: '400',
				lineHeight: '130%',
			},
			xxs: {
				// Extra small: 10px
				fontSize: '0.625rem',
				fontWeight: '600',
				lineHeight: '160%',
				letterSpacing: '0.2px',
			},
		},
		noOfLines: {
			1: {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
		},
	},
});
