import { sva } from '@/styled-system/css';

export const input = sva({
	slots: ['root', 'inputWrapper', 'label', 'input', 'clearButton', 'inputFooter', 'helperText', 'error'],
	variants: {
		variant: {
			sm: {
				inputWrapper: {
					p: 1,
					pl: 3,
				},
				input: {
					my: 0,
				},
			},
			md: {},
		},
		disabled: {
			true: {
				inputWrapper: {
					bg: 'surface.interactive.transparent.regular.disabled',
					borderColor: 'transparent',
				},
				input: {
					color: 'text.regular.disabled',
					bg: 'surface.interactive.transparent.regular.disabled',
					_placeholder: {
						color: 'text.regular.disabled',
					},
				},
			},
		},
		readOnly: {
			true: {
				inputWrapper: {
					bg: 'surface.interactive.transparent.regular.disabled',
					borderColor: 'transparent',
				},
				input: {
					color: 'text.regular.subtitle',
					bg: 'surface.interactive.transparent.regular.disabled',
					_placeholder: {
						color: 'text.regular.subtitle',
					},
				},
			},
		},
		isError: {
			true: {
				inputWrapper: {
					borderColor: 'trafficLights.error.default',

					_focusWithin: {
						borderColor: 'trafficLights.error.default',
					},
				},
				helperText: {
					color: 'text.regular.danger',
				},
				error: {
					color: 'text.regular.danger',
				},
			},
		},
		hidden: {
			true: {
				root: {
					display: 'none',
				},
			},
		},
	},
	base: {
		root: {
			w: 'full',
		},
		label: {
			fontSize: 12,
			fontWeight: 600,
			color: 'text.regular.default',
		},
		inputWrapper: {
			display: 'flex',
			alignItems: 'center',
			w: 'full',
			borderWidth: 1,
			borderColor: 'other.border.regular.default',
			borderRadius: 6,
			p: 1.5,
			pl: 3,

			_focusWithin: {
				borderColor: 'other.border.regular.focus',
			},
		},
		input: {
			w: 'full',
			fontSize: 16,
			outline: 0,
			border: '1px solid transparent',
			my: 0.5,

			_placeholder: {
				color: 'text.regular.subtitle',
			},
		},
		clearButton: {
			bg: 'icons.regular.default',
			h: 4,
			minH: 4,
			w: 4,
			minW: 4,
			m: 1,
			border: 0,
		},
		inputFooter: {
			display: 'flex',
			alignItems: 'center',
			mt: 2,
			gap: 1,
		},
		helperText: {
			color: 'text.regular.subtitle',
		},
	},
});
