import { Heading } from '@/components/core/Heading/Heading';
import { Text } from '@/components/core/Text/Text';
import { TimeIcon } from '@/icons/TimeIcon';
import { sva } from '@/styled-system/css';
import { Box, Flex, Stack } from '@/styled-system/jsx';
import { formatRelativeDate } from '@/utils/i18n/locale-formatting/localeFormatting';
import Link from 'next/link';
import { FC } from 'react';

interface IJobItemProps {
	job: {
		title: string;
		startPublish: string;
		jobOrgGf: string;
		locationDescription: string;
		url: string;
	};
	language: string;
}

const inlineJobItemRecipe = sva({
	slots: ['container', 'date', 'label'],
	base: {
		container: {
			borderRadius: 'sm',
			borderWidth: '1px',
			borderStyle: 'solid',
			px: 6,
			py: { base: 2, xl: 4, lg: 4, md: 4 },
			mb: 4,
		},
		label: {
			flex: 1,
			display: 'flex',
			alignItems: 'center',
			py: 2,
		},
	},
	defaultVariants: {
		backgroundVariant: 'white',
	},
	variants: {
		backgroundVariant: {
			white: {
				container: {
					color: 'text.regular.default',
					backgroundColor: 'surface.interactive.card.pri',
					borderColor: 'other.border.regular.default',
					_hover: {
						backgroundColor: 'surface.interactive.transparent.regular.pressed',
						borderColor: 'transparent',
					},
				},
				date: { color: 'text.regular.subtitle' },
			},
		},
	},
});

export const JobItem: FC<IJobItemProps> = ({ job, language }) => {
	const classes = inlineJobItemRecipe();

	if (!job || !job.url || !job.title || !job.startPublish || !job.jobOrgGf || !job.locationDescription) {
		return null;
	}

	return (
		<Link title={job.title} href={job.url}>
			<Stack className={classes.container} direction={{ base: 'column', md: 'row', lg: 'row', xl: 'row' }} gap={0}>
				<Box flex={2} py={{ base: 4, xl: 2, lg: 2, md: 2 }}>
					<Heading as="p" type="h6">
						{job.title}
					</Heading>
					<Flex mt={2} alignItems="center">
						<TimeIcon width={16} />
						<Text size="xs" ml={1} lineHeight="16px" className={classes.date}>
							{formatRelativeDate(job.startPublish, language)}
						</Text>
					</Flex>
				</Box>
				<Stack
					direction={{ base: 'column', lg: 'row', xl: 'row' }}
					flex={{ base: 1, lg: 2, xl: 2 }}
					gap={0}
					ml={{ base: 0, xl: 4, lg: 4, md: 4 }}
				>
					<Text size="sm" className={classes.label}>
						{job.jobOrgGf}{' '}
					</Text>
					<Text size="sm" className={classes.label}>
						{job.locationDescription}
					</Text>
				</Stack>
			</Stack>
		</Link>
	);
};
