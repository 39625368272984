import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const tagsDefaultVariants = {
  "size": "md"
}
const tagsCompoundVariants = []

const tagsSlotNames = [
  [
    "root",
    "tags__root"
  ],
  [
    "item",
    "tags__item"
  ],
  [
    "label",
    "tags__label"
  ],
  [
    "closeButton",
    "tags__closeButton"
  ]
]
const tagsSlotFns = /* @__PURE__ */ tagsSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, tagsDefaultVariants, getSlotCompoundVariant(tagsCompoundVariants, slotName))])

const tagsFn = memo((props = {}) => {
  return Object.fromEntries(tagsSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const tagsVariantKeys = [
  "size"
]

export const tags = /* @__PURE__ */ Object.assign(tagsFn, {
  __recipe__: false,
  __name__: 'tags',
  raw: (props) => props,
  variantKeys: tagsVariantKeys,
  variantMap: {
  "size": [
    "md"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, tagsVariantKeys)
  },
})