import { Icon } from '@/enums/Icon';
import { MimeType } from '@/enums/MimeType';
import dynamic from 'next/dynamic';

const CalculatorIcon = dynamic(() => import('@/icons/CalculatorIcon').then((mod) => mod.CalculatorIcon));
const AlertIcon = dynamic(() => import('@/icons/AlertIcon').then((mod) => mod.AlertIcon));
const AlphaPyramideIcon = dynamic(() => import('@/icons/AlphaPyramideIcon').then((mod) => mod.AlphaPyramideIcon));
const AssetAlocationIcon = dynamic(() => import('@/icons/AssetAlocationIcon').then((mod) => mod.AssetAlocationIcon));
const BookmarkIcon = dynamic(() => import('@/icons/BookmarkIcon').then((mod) => mod.BookmarkIcon));
const CalendarIcon = dynamic(() => import('@/icons/CalendarIcon').then((mod) => mod.CalendarIcon));
const ClarityInnovationIcon = dynamic(() =>
	import('@/icons/ClarityInnovationIcon').then((mod) => mod.ClarityInnovationIcon)
);
const ClientExperienceIcon = dynamic(() =>
	import('@/icons/ClientExperienceIcon').then((mod) => mod.ClientExperienceIcon)
);
const ClientManIcon = dynamic(() => import('@/icons/ClientManIcon').then((mod) => mod.ClientManIcon));
const ClientOnboardingIcon = dynamic(() =>
	import('@/icons/ClientOnboardingIcon').then((mod) => mod.ClientOnboardingIcon)
);
const ClientWomanIcon = dynamic(() => import('@/icons/ClientWomanIcon').then((mod) => mod.ClientWomanIcon));
const CloseIcon = dynamic(() => import('@/icons/CloseIcon').then((mod) => mod.CloseIcon));
const CompanyIcon = dynamic(() => import('@/icons/CompanyIcon').then((mod) => mod.CompanyIcon));
const CompassIcon = dynamic(() => import('@/icons/CompassIcon').then((mod) => mod.CompassIcon));
const ContactIcon = dynamic(() => import('@/icons/ContactIcon').then((mod) => mod.ContactIcon));
const CopyIcon = dynamic(() => import('@/icons/CopyIcon').then((mod) => mod.CopyIcon));
const DialogIcon = dynamic(() => import('@/icons/DialogIcon').then((mod) => mod.DialogIcon));
const DialogSimpleIcon = dynamic(() => import('@/icons/DialogSimpleIcon').then((mod) => mod.DialogSimpleIcon));
const DisclamerIcon = dynamic(() => import('@/icons/DisclamerIcon').then((mod) => mod.DisclamerIcon));
const DocumentIcon = dynamic(() => import('@/icons/DocumentIcon').then((mod) => mod.DocumentIcon));
const DontTalkIcon = dynamic(() => import('@/icons/DontTalkIcon').then((mod) => mod.DontTalkIcon));
const EmailIcon = dynamic(() => import('@/icons/EmailIcon').then((mod) => mod.EmailIcon));
const EnvironmentalIcon = dynamic(() => import('@/icons/EnvironmentalIcon').then((mod) => mod.EnvironmentalIcon));
const EquitiesIcon = dynamic(() => import('@/icons/EquitiesIcon').then((mod) => mod.EquitiesIcon));
const ExchangeIcon = dynamic(() => import('@/icons/ExchangeIcon').then((mod) => mod.ExchangeIcon));
const ExternalLinkIcon = dynamic(() => import('@/icons/ExternalLinkIcon').then((mod) => mod.ExternalLinkIcon));
const FacebookIcon = dynamic(() => import('@/icons/FacebookIcon').then((mod) => mod.FacebookIcon));
const HelpIcon = dynamic(() => import('@/icons/HelpIcon').then((mod) => mod.HelpIcon));
const HomeIcon = dynamic(() => import('@/icons/HomeIcon').then((mod) => mod.HomeIcon));
const InstagramIcon = dynamic(() => import('@/icons/InstagramIcon').then((mod) => mod.InstagramIcon));
const LeafIcon = dynamic(() => import('@/icons/LeafIcon').then((mod) => mod.LeafIcon));
const LinkedinIcon = dynamic(() => import('@/icons/LinkedinIcon').then((mod) => mod.LinkedinIcon));
const LocationIcon = dynamic(() => import('@/icons/LocationIcon').then((mod) => mod.LocationIcon));
const LoginIcon = dynamic(() => import('@/icons/LoginIcon').then((mod) => mod.LoginIcon));
const MobileIcon = dynamic(() => import('@/icons/MobileIcon').then((mod) => mod.MobileIcon));
const MultipolarIcon = dynamic(() => import('@/icons/MultipolarIcon').then((mod) => mod.MultipolarIcon));
const OkIcon = dynamic(() => import('@/icons/OkIcon').then((mod) => mod.OkIcon));
const PenIcon = dynamic(() => import('@/icons/PenIcon').then((mod) => mod.PenIcon));
const PersonFemalIcon = dynamic(() => import('@/icons/PersonFemalIcon').then((mod) => mod.PersonFemalIcon));
const PrecautionIcon = dynamic(() => import('@/icons/PrecautionIcon').then((mod) => mod.PrecautionIcon));
const PuzzleIcon = dynamic(() => import('@/icons/PuzzleIcon').then((mod) => mod.PuzzleIcon));
const RmGuidanceIcon = dynamic(() => import('@/icons/RmGuidanceIcon').then((mod) => mod.RmGuidanceIcon));
const RocketIcon = dynamic(() => import('@/icons/RocketIcon').then((mod) => mod.RocketIcon));
const SavingIcon = dynamic(() => import('@/icons/SavingIcon').then((mod) => mod.SavingIcon));
const SelectionChoiceIcon = dynamic(() => import('@/icons/SelectionChoiceIcon').then((mod) => mod.SelectionChoiceIcon));
const SeveralPersonsIcon = dynamic(() => import('@/icons/SeveralPersonsIcon').then((mod) => mod.SeveralPersonsIcon));
const ShareIcon = dynamic(() => import('@/icons/ShareIcon').then((mod) => mod.ShareIcon));
const Status100Icon = dynamic(() => import('@/icons/Status100Icon').then((mod) => mod.Status100Icon));
const Status25Icon = dynamic(() => import('@/icons/Status25Icon').then((mod) => mod.Status25Icon));
const Status50Icon = dynamic(() => import('@/icons/Status50Icon').then((mod) => mod.Status50Icon));
const Status75Icon = dynamic(() => import('@/icons/Status75Icon').then((mod) => mod.Status75Icon));
const StrategyIcon = dynamic(() => import('@/icons/StrategyIcon').then((mod) => mod.StrategyIcon));
const SwissIcon = dynamic(() => import('@/icons/SwissIcon').then((mod) => mod.SwissIcon));
const ThumbDownIcon = dynamic(() => import('@/icons/ThumbDownIcon').then((mod) => mod.ThumbDownIcon));
const ThumbUpIcon = dynamic(() => import('@/icons/ThumbUpIcon').then((mod) => mod.ThumbUpIcon));
const TimeIcon = dynamic(() => import('@/icons/TimeIcon').then((mod) => mod.TimeIcon));
const TransactionIcon = dynamic(() => import('@/icons/TransactionIcon').then((mod) => mod.TransactionIcon));
const TrendsIcon = dynamic(() => import('@/icons/TrendsIcon').then((mod) => mod.TrendsIcon));
const TrashIcon = dynamic(() => import('@/icons/TrashIcon').then((mod) => mod.TrashIcon));
const ValueIcon = dynamic(() => import('@/icons/ValueIcon').then((mod) => mod.ValueIcon));
const YoutubeIcon = dynamic(() => import('@/icons/YoutubeIcon').then((mod) => mod.YoutubeIcon));
const ArrowRightIcon = dynamic(() => import('@/icons/ArrowRightIcon').then((mod) => mod.ArrowRightIcon));
const MenuIcon = dynamic(() => import('@/icons/MenuIcon').then((mod) => mod.MenuIcon));
const TwitterIcon = dynamic(() => import('@/icons/TwitterIcon').then((mod) => mod.TwitterIcon));
const MinusIcon = dynamic(() => import('@/icons/MinusIcon').then((mod) => mod.MinusIcon));
const PlayIcon = dynamic(() => import('@/icons/PlayIcon').then((mod) => mod.PlayIcon));
const PlusIcon = dynamic(() => import('@/icons/PlusIcon').then((mod) => mod.PlusIcon));
const SearchIcon = dynamic(() => import('@/icons/SearchIcon').then((mod) => mod.SearchIcon));
const StarIcon = dynamic(() => import('@/icons/StarIcon').then((mod) => mod.StarIcon));
const DownloadIcon = dynamic(() => import('@/icons/DownloadIcon').then((mod) => mod.DownloadIcon));
const DocumentPdfIcon = dynamic(() => import('@/icons/DocumentPdfIcon').then((mod) => mod.DocumentPdfIcon));
const DocumentAVIIcon = dynamic(() => import('@/icons/DocumentAVIIcon').then((mod) => mod.DocumentAVIIcon));
const DocumentDOCIcon = dynamic(() => import('@/icons/DocumentDOCIcon').then((mod) => mod.DocumentDOCIcon));
const DocumentPPTIcon = dynamic(() => import('@/icons/DocumentPPTIcon').then((mod) => mod.DocumentPPTIcon));
const DocumentTXTIcon = dynamic(() => import('@/icons/DocumentTXTIcon').then((mod) => mod.DocumentTXTIcon));
const DocumentXLSIcon = dynamic(() => import('@/icons/DocumentXLSIcon').then((mod) => mod.DocumentXLSIcon));
const DocumentZipIcon = dynamic(() => import('@/icons/DocumentZipIcon').then((mod) => mod.DocumentZipIcon));

export const getIcon = (icon: Icon) => {
	switch (icon) {
		case Icon.ALERT:
			return <AlertIcon />;
		case Icon.ALPHA_PYRAMIDE:
			return <AlphaPyramideIcon />;
		case Icon.ARROW_RIGHT:
			return <ArrowRightIcon />;
		case Icon.ASSET_ALLOCATION:
			return <AssetAlocationIcon />;
		case Icon.BOOKMARK:
			return <BookmarkIcon />;
		case Icon.CALCULATOR:
			return <CalculatorIcon />;
		case Icon.CALENDAR:
			return <CalendarIcon />;
		case Icon.CLARITY_INNOVATION:
			return <ClarityInnovationIcon />;
		case Icon.CLIENT_EXPERIENCE:
			return <ClientExperienceIcon />;
		case Icon.CLIENT_MAN:
			return <ClientManIcon />;
		case Icon.CLIENT_ONBOARDING:
			return <ClientOnboardingIcon />;
		case Icon.CLIENT_WOMAN:
			return <ClientWomanIcon />;
		case Icon.CLOSE:
			return <CloseIcon />;
		case Icon.COMPANY:
			return <CompanyIcon />;
		case Icon.COMPASS:
			return <CompassIcon />;
		case Icon.CONTACT:
			return <ContactIcon />;
		case Icon.COPY:
			return <CopyIcon />;
		case Icon.DIALOG:
			return <DialogIcon />;
		case Icon.DIALOG_SIMPLE:
			return <DialogSimpleIcon />;
		case Icon.DISCLAIMER:
			return <DisclamerIcon />;
		case Icon.DOCUMENT:
			return <DocumentIcon />;
		case Icon.DONT_TALK:
			return <DontTalkIcon />;
		case Icon.EMAIL:
			return <EmailIcon />;
		case Icon.ENVIRONMENTAL:
			return <EnvironmentalIcon />;
		case Icon.EQUITIES:
			return <EquitiesIcon />;
		case Icon.EXCHANGE:
			return <ExchangeIcon />;
		case Icon.EXTERNAL_LINK:
			return <ExternalLinkIcon />;
		case Icon.FACEBOOK:
			return <FacebookIcon />;
		case Icon.HELP:
			return <HelpIcon />;
		case Icon.HOME:
			return <HomeIcon />;
		case Icon.INSTAGRAM:
			return <InstagramIcon />;
		case Icon.LEAF:
			return <LeafIcon />;
		case Icon.LINKEDIN:
			return <LinkedinIcon />;
		case Icon.LOCATION:
			return <LocationIcon />;
		case Icon.LOGIN:
			return <LoginIcon />;
		case Icon.MENU:
			return <MenuIcon />;
		case Icon.MINUS:
			return <MinusIcon />;
		case Icon.MOBILE:
			return <MobileIcon />;
		case Icon.MULTIPOLAR:
			return <MultipolarIcon />;
		case Icon.OK:
			return <OkIcon />;
		case Icon.PEN:
			return <PenIcon />;
		case Icon.PERSON_FEMAL:
			return <PersonFemalIcon />;
		case Icon.PLAY:
			return <PlayIcon />;
		case Icon.PLUS:
			return <PlusIcon />;
		case Icon.PRECAUTION:
			return <PrecautionIcon />;
		case Icon.PUZZLE:
			return <PuzzleIcon />;
		case Icon.RM_GUIDEANCE:
			return <RmGuidanceIcon />;
		case Icon.ROCKET:
			return <RocketIcon />;
		case Icon.SAVING:
			return <SavingIcon />;
		case Icon.SEARCH:
			return <SearchIcon />;
		case Icon.SELECTION_CHOICE:
			return <SelectionChoiceIcon />;
		case Icon.SEVERAL_PERSONS:
			return <SeveralPersonsIcon />;
		case Icon.SHARE:
			return <ShareIcon />;
		case Icon.STATUS_25:
			return <Status25Icon />;
		case Icon.STATUS_50:
			return <Status50Icon />;
		case Icon.STATUS_75:
			return <Status75Icon />;
		case Icon.STATUS_100:
			return <Status100Icon />;
		case Icon.STAR:
			return <StarIcon />;
		case Icon.STRATEGY:
			return <StrategyIcon />;
		case Icon.SWISS:
			return <SwissIcon />;
		case Icon.THUMB_DOWN:
			return <ThumbDownIcon />;
		case Icon.THUMB_UP:
			return <ThumbUpIcon />;
		case Icon.TIME:
			return <TimeIcon />;
		case Icon.TRANSACTION:
			return <TransactionIcon />;
		case Icon.TRASH:
			return <TrashIcon />;
		case Icon.TRENDS:
			return <TrendsIcon />;
		case Icon.TWITTER:
			return <TwitterIcon />;
		case Icon.VALUE:
			return <ValueIcon />;
		case Icon.YOUTUBE:
			return <YoutubeIcon />;
		case Icon.DOWNLOAD:
			return <DownloadIcon />;
		case Icon.DOCUMENT_PDF:
			return <DocumentPdfIcon />;
		case Icon.DOCUMENT_AVI:
			return <DocumentAVIIcon />;
		case Icon.DOCUMENT_DOC:
			return <DocumentDOCIcon />;
		case Icon.DOCUMENT_PPT:
			return <DocumentPPTIcon />;
		case Icon.DOCUMENT_TXT:
			return <DocumentTXTIcon />;
		case Icon.DOCUMENT_XLS:
			return <DocumentXLSIcon />;
		case Icon.DOCUMENT_ZIP:
			return <DocumentZipIcon />;

		default:
			return undefined;
	}
};

export const getIconByMimeType = (mimeType: MimeType) => {
	switch (mimeType) {
		case MimeType.PDF:
			return <DocumentPdfIcon />;
		case MimeType.DOC:
			return <DocumentDOCIcon />;
		case MimeType.DOCX:
			return <DocumentDOCIcon />;
		case MimeType.XLS:
			return <DocumentXLSIcon />;
		case MimeType.XLSX:
			return <DocumentXLSIcon />;
		case MimeType.PPT:
			return <DocumentPPTIcon />;
		case MimeType.PPTX:
			return <DocumentPPTIcon />;
		case MimeType.AVI:
			return <DocumentAVIIcon />;
		case MimeType.TXT:
			return <DocumentTXTIcon />;
		case MimeType.ZIP:
			return <DocumentZipIcon />;

		default:
			return undefined;
	}
};
