'use client';

import { VisibilitySettings } from '@/components/block-templates/Jobs/JobFilterBlock/JobFilterBlockTemplate.utils';
import { IDropdownOption } from '@/components/core/Dropdown/Dropdown';
import { JobItem } from '@/components/shared/Jobs/JobItem';
import { Job, JobFilterTags, areJobFiltersApplied, filterJobResults } from '@/components/shared/Jobs/Jobs.utils';
import { JobFilter } from '@/components/shared/Jobs/JobsFilter';
import { FilterCount } from '@/components/shared/filters/FilterCount';
import { FilterEmptyState } from '@/components/shared/filters/FilterEmptyState';
import { FilterLocationWrapper } from '@/components/shared/filters/FilterLocationWrapper';
import useQueryParams from '@/hooks/useQueryParams';
import { Flex, Spacer } from '@/styled-system/jsx';
import { TranslationLabelValues, translate, translatePlural } from '@/utils/i18n/translation-labels/translationLabels';
import { FC, useMemo } from 'react';

interface IJobsProps {
	id: string;
	locations: Array<IDropdownOption>;
	departments: Array<IDropdownOption>;
	jobs: Array<Job>;
	language: string;
	translations: TranslationLabelValues;
	visibilitySettings: VisibilitySettings;
}

export const Jobs: FC<IJobsProps> = ({
	id,
	jobs,
	language,
	locations,
	departments,
	translations,
	visibilitySettings,
}) => {
	const { query } = useQueryParams();

	const filteredJobs = useMemo(() => {
		return filterJobResults(jobs, query);
	}, [jobs, query]);

	return (
		<FilterLocationWrapper id={id} w="100%" alignItems="normal">
			<JobFilter
				locations={locations}
				departments={departments}
				translations={translations}
				visibilitySettings={visibilitySettings}
			/>

			<Flex
				w="100%"
				display={areJobFiltersApplied(query) ? 'flex' : 'none'}
				flexDirection={{ base: 'column', md: 'row' }}
				alignItems="baseline"
				justifyContent="space-between"
				mt={3}
				gap={2}
			>
				<JobFilterTags filters={{ locations, departments }} />
				<FilterCount
					label={translatePlural(
						translations,
						language,
						'jobs.filter.results.count',
						{ count: filteredJobs.length },
						''
					)}
				/>
			</Flex>

			<Spacer mt={{ base: 4, md: 8 }} />
			{filteredJobs?.length === 0 ? (
				<FilterEmptyState
					title={translate(translations, 'jobs.filter.empty.title')}
					body={translate(translations, 'jobs.filter.empty.text')}
					borderWidth="1px"
					borderStyle="solid"
					borderColor="other.border.regular.default"
					borderRadius={6}
					bg="surface.interactive.card.primary"
				/>
			) : null}
			{filteredJobs.map((job) => (
				<JobItem job={job} key={job.url} language={language} />
			))}
		</FilterLocationWrapper>
	);
};
