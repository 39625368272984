import type { SVGAttributes } from 'react';

export const LinkedinIcon = (props: SVGAttributes<SVGElement>) => (
	<svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fill="currentColor"
			d="M12.8842 14.4315C12.8733 13.4761 13.6333 12.689 14.5887 12.6781C14.6376 12.6781 14.6919 12.6781 14.7407 12.6781C15.6582 12.7432 16.3747 13.5032 16.3802 14.4261V19.8654H19.8761V14.4261C19.8761 11.3427 18.3236 9.68157 16.1467 9.68157C14.9633 9.63814 13.807 10.0181 12.8842 10.751V9.75757H9.42082V19.8654H12.8842V14.4261V14.4315Z"
		/>
		<path fill="currentColor" d="M7.83027 9.76843H4.33432V19.8763H7.83027V9.76843Z" />
		<path
			fill="currentColor"
			d="M6.15827 8.40581C6.15827 8.40581 6.20713 8.40581 6.22884 8.40581H6.2777C7.42854 8.43296 8.38938 7.52097 8.41653 6.37013C8.41653 6.33756 8.41653 6.29956 8.41653 6.26699C8.4491 5.12158 7.54254 4.16073 6.39713 4.12816C6.35913 4.12816 6.3157 4.12816 6.2777 4.12816C5.14314 4.07931 4.17687 4.95872 4.12801 6.0987C4.12801 6.14756 4.12801 6.19099 4.12801 6.23985C4.09001 7.39611 4.99657 8.36781 6.15827 8.40581Z"
		/>
	</svg>
);
