import { publicDomainUrl } from '@/constants/global';

export const convertToWebsiteUrl = (fqUrl: string) => {
	if (!fqUrl) {
		return '';
	}

	let url;

	try {
		url = new URL(fqUrl);
	} catch (error) {
		console.debug(`[convertToWebsiteUrl] Error converting to URL: ${fqUrl}} - `, error);

		return fqUrl;
	}

	return publicDomainUrl + url.pathname;
};

export const isCmsUrl = (url?: string) => {
	if (!url || url.length === 0) {
		return false;
	}

	const cmsUrlRegex = new RegExp('cms+.vontobel.com');

	return cmsUrlRegex.test(url);
};

export const lookupCaseInsensitive = <T = any>(dictionary: { [key: string]: T }, key: string): T | undefined => {
	const keyWithCasing = Object.keys(dictionary).find((dictKey) => dictKey.toLowerCase() === key.toLowerCase());

	return keyWithCasing ? dictionary[keyWithCasing] : undefined;
};
