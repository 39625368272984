'use client';

import Script from 'next/script';
import { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface ITealiumUniversalDataProviderProps {
	pageTrackingData: Record<string, unknown>;
	nonce?: string;
}

export const TealiumUniversalDataProvider: FC<ITealiumUniversalDataProviderProps> = ({ pageTrackingData, nonce }) => {
	const [scriptId, setScriptId] = useState(uuidv4());

	useEffect(() => {
		// Generate a new unique id for the script tag to ensure re-execution/reload page change when pageTrackingData change
		setScriptId(uuidv4());
	}, [pageTrackingData]);

	if (typeof window === 'undefined') {
		return null;
	}

	return (
		<Script
			id={`utag-data-script-${scriptId}`}
			nonce={nonce}
		>{`var utag_data = ${JSON.stringify(pageTrackingData)};`}</Script>
	);
};
