'use client';

import { Button } from '@/components/core/Button/Button';
import { Heading } from '@/components/core/Heading/Heading';
import { ContentContainer } from '@/components/shared/ContentContainer/ContentContainer';
import { ButtonsToHide, IDisclaimerFunctionality, IDisplaySettings } from '@/components/shared/Disclaimer/Disclaimer';
import {
	TealiumConsentState,
	acceptCookies,
	getConsentState,
	tealiumConsentExists,
} from '@/components/shared/Disclaimer/Disclaimer.utils';
import { TextSnippet } from '@/components/shared/TextSnippet/TextSnippet';
import { BackgroundColorVariant } from '@/enums/ColorMode';
import { CloseIcon } from '@/icons/CloseIcon';
import { Box, BoxProps, Flex, VStack } from '@/styled-system/jsx';
import { logger } from '@/utils/logger';
import { CustomTrackingEvent, doTrack } from '@/utils/tracking/tracking';
import { RefObject, useEffect, useMemo } from 'react';

interface ICookieDisclaimerProps extends BoxProps {
	displaySettings: IDisplaySettings;
	disclaimerFunctionality: IDisclaimerFunctionality;
	displayTogether?: boolean;
	setIsOpen: (isOpen: boolean) => void;
	setSettingsOpen: (settingsOpen: boolean) => void;
	translations: Record<string, string>;
	language: string;
	trackingRef: RefObject<HTMLDivElement>;
	customTermsCookieKey?: string;
	onSubmit?: () => void;
	disabled?: boolean;
}

export const CookieDisclaimer = (props: ICookieDisclaimerProps) => {
	const {
		displaySettings,
		disclaimerFunctionality,
		displayTogether,
		setIsOpen,
		setSettingsOpen,
		translations,
		language,
		trackingRef,
		customTermsCookieKey,
		disabled,
		onSubmit,
		...rest
	} = props;

	const buttonsToHide = displaySettings?.buttonsToHide?.split(',');
	const disclaimerOptions = disclaimerFunctionality?.disclaimerOptions?.split(',');

	const handleFunctionalOnly = async () => {
		if (!trackingRef.current) {
			logger.debug('Accepted functional cookies, but no element to attach the tracking event to could be found.');

			return;
		}

		acceptCookies(trackingRef.current, TealiumConsentState.FunctionalOnly);

		onSubmit && onSubmit();
		setIsOpen(false);
	};

	const handleAccept = async () => {
		if (!trackingRef.current) {
			logger.debug('Accepted functional cookies, but no element to attach the tracking event to could be found.');

			return;
		}

		const consentState = getConsentState();

		acceptCookies(
			trackingRef.current,
			consentState === TealiumConsentState.None ? TealiumConsentState.AcceptedAll : consentState
		);

		onSubmit && onSubmit();
		setIsOpen(false);
	};

	const close = () => {
		acceptCookies(trackingRef.current!, TealiumConsentState.FunctionalOnly);
		setIsOpen(false);
	};

	const isButtonHidden =
		buttonsToHide?.includes(ButtonsToHide.FUNCTIONAL_ONLY) || buttonsToHide?.includes(ButtonsToHide.ACCEPT);

	const handleSettingsOpen = () => {
		setSettingsOpen(true);
		setIsOpen(false);

		if (trackingRef?.current) {
			doTrack(CustomTrackingEvent.FromTarget(trackingRef.current, 'settingsShow'));
		}
	};

	// Only decide on initial load, whether functional button should be displayed
	const showFunctional: boolean = useMemo(() => {
		return !buttonsToHide?.includes(ButtonsToHide.FUNCTIONAL_ONLY) && !tealiumConsentExists();
	}, []);

	useEffect(() => {
		const cookieSettingsLink = document.querySelector('.cookieSettingsTrigger') as HTMLElement;

		cookieSettingsLink?.addEventListener('click', handleSettingsOpen);
	});

	const getAcceptButtonText = (): string => {
		return getConsentState() === TealiumConsentState.AcceptedPartially || tealiumConsentExists()
			? translations['disclaimer.button.accept.text']
			: translations['disclaimer.button.acceptAll.text'];
	};

	return (
		<>
			<Box {...rest}>
				<ContentContainer providePadding={false}>
					<Flex direction={{ base: 'column', md: 'row' }} justify="space-between" gap={{ base: 6, md: 8, lg: 12 }}>
						<VStack className="Introduction" gap={2} alignItems="left">
							{!displayTogether && <Heading as="h5">{displaySettings.disclaimerTitleText}</Heading>}
							{displaySettings.disclaimerCookieSectionText && (
								<TextSnippet
									id="disclaimerCookieSectionText"
									content={displaySettings.disclaimerCookieSectionText}
									parentBackgroundColor={BackgroundColorVariant.PRIMARY}
									dataId="disclaimer-cookie-section-text"
								/>
							)}
						</VStack>
						<VStack className={`Buttons ${isButtonHidden ? '-hidden' : ''}`} gap={2}>
							{showFunctional && (
								<Button
									onClick={handleFunctionalOnly}
									variant="secondary"
									title={translations['disclaimer.button.functional.title']}
									disabled={disabled}
								>
									{translations['disclaimer.button.functional.text']}
								</Button>
							)}
							{!buttonsToHide?.includes(ButtonsToHide.ACCEPT) && (
								<Button
									id="accept-cookies-button"
									onClick={handleAccept}
									variant="primary"
									title={translations['disclaimer.button.accept.title']}
									disabled={disabled}
								>
									{getAcceptButtonText()}
								</Button>
							)}
						</VStack>
					</Flex>
				</ContentContainer>
				{disclaimerOptions?.includes('FunctionalOnlyOnClose') && (
					<Button
						className="CloseButton"
						onClick={() => close()}
						variant="unstyled"
						size="small"
						leftIcon={<CloseIcon width={16} height={16} />}
					/>
				)}
			</Box>
		</>
	);
};
