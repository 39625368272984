import { FC } from 'react';
import { generateTrackingId, getTrackingHref } from '@/utils/tracking/tracking';
import { ITrackedProps, Tracked } from '@/components/shared/tracking/Tracked';
import { ITrackingTarget } from '@/types/tracking';
import { publicDomainUrl } from '@/constants/global';
import { ILinkProps, Link } from '@/components/core/Link/Link';

interface ILinkTrackedProps extends ILinkProps {
	trackingData?: Partial<ITrackedProps>;
	trackingTarget?: ITrackingTarget;
	preventAutomatedTracking?: boolean;
	children?: React.ReactNode;
}

export const LinkTracked: FC<ILinkTrackedProps> = ({
	trackingData = {},
	trackingTarget,
	preventAutomatedTracking,
	href,
	children,
	...props
}) => {
	if (preventAutomatedTracking) {
		return (
			<Link href={href} {...props}>
				{children}
			</Link>
		);
	}

	const trackingId = generateTrackingId();

	return (
		<Tracked
			{...trackingData}
			trackingId={trackingId}
			trackingTarget={trackingTarget}
			href={getTrackingHref(href, publicDomainUrl)}
		>
			<Link href={href} {...props} data-trackingid={trackingId}>
				{children}
			</Link>
		</Tracked>
	);
};
