import { Text } from '@/components/core/Text/Text';
import { Divider } from '@/components/shared/Categories/Categories';
import { FC } from '@/interfaces/blocks/shared';
import { Stack } from '@/styled-system/jsx';
import { formatPublicationDateToEuZurich } from '@/utils/i18n/locale-formatting/localeFormatting';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';

interface IArticlePublicationInfoProps {
	articleTranslations: TranslationLabelValues;
	timezoneTranslations: TranslationLabelValues;
	showRevisedDateInsteadOfPublishDate?: boolean;
	revisedDate?: string;
	publicationDate?: string;
	includePrefixes?: boolean;
	textSize?: 'sm' | 'xs';
}

export const ArticlePublicationInfo: FC<IArticlePublicationInfoProps> = ({
	articleTranslations,
	timezoneTranslations,
	revisedDate,
	publicationDate,
	showRevisedDateInsteadOfPublishDate,
	includePrefixes = true,
	textSize = 'sm',
}) => {
	const publishedOnPrefix = translate(articleTranslations, 'article.page.publishedOn');
	const updatedAtPrefix = translate(articleTranslations, 'article.page.updatedAt');

	const publicationDateObj = publicationDate ? new Date(publicationDate) : undefined;
	const revisedDateObj = revisedDate ? new Date(revisedDate) : undefined;

	return (
		<Stack gap={0} color="text.regular.subtitle" alignItems="flex-start" direction={{ base: 'column', md: 'row' }}>
			{showRevisedDateInsteadOfPublishDate && revisedDate ? (
				<>
					<Text size={textSize}>
						{formatPublicationDateToEuZurich(
							revisedDateObj,
							includePrefixes ? updatedAtPrefix : '',
							true,
							timezoneTranslations
						)}
					</Text>
					<Divider hideBelow="md" />
				</>
			) : null}
			<Text size={textSize}>
				{formatPublicationDateToEuZurich(
					publicationDateObj,
					includePrefixes ? publishedOnPrefix : '',
					true,
					timezoneTranslations
				)}
			</Text>
		</Stack>
	);
};
