import { sva } from '@/styled-system/css';

export const fileUpload = sva({
	slots: ['root', 'dropzone', 'infoContainer', 'constraints', 'fieldInformation', 'uploadedItemsContainer'],
	base: {
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		dropzone: {
			cursor: 'pointer',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			border: '2px dashed',
			borderRadius: 4,
			borderColor: 'other.border.regular.default',
			paddingBlock: 10,
			paddingInline: 4,
			_hover: {
				borderColor: 'actions.uploads.strokeHover',
				backgroundColor: 'actions.uploads.fillHover',
			},
			_active: {
				borderColor: 'actions.uploads.strokeHover',
				backgroundColor: 'actions.uploads.fillHover',
			},
			'&[data-dragging]': {
				borderColor: 'actions.uploads.strokeHover',
				backgroundColor: 'actions.uploads.fillHover',
			},
			'&[data-window-dragging="true"]': {
				borderColor: 'actions.uploads.strokeHover',
			},
		},
		infoContainer: {
			textAlign: 'center',
			flexDirection: 'column',
			gap: 2,
			alignItems: 'center',
		},
		constraints: {
			color: 'text.regular.subtitle',
			textAlign: 'center',
		},
		fieldInformation: {
			marginBottom: 8,
			display: 'flex',
			flexDirection: 'column',
			gap: 1,
		},
		uploadedItemsContainer: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			gap: 4,
			'& > ul': {
				gap: 0,
				rowGap: 4,
				display: 'flex',
				flexDirection: 'row',
				width: '100%',
				flexWrap: 'wrap',
			},
		},
	},
});

export const fileUploadItem = sva({
	slots: ['uploadedItemWrapper', 'uploadedItemContent', 'actionIcon', 'stateIcon', 'uploadedItemTextContent'],
	base: {
		uploadedItemWrapper: {
			display: 'flex',
			width: {
				sm: '100%',
				lg: '50%',
			},
			paddingRight: 4,
			/* Needed in order to apply peer hover effect */
			flexDirection: 'row-reverse',
			gap: 4,
			alignItems: 'center',
			'& > button': {
				height: 'min-content',
				padding: 2,
				borderRadius: '50%',
				color: 'icons.regular.default',
				_hover: {
					backgroundColor: 'actions.secondary.regular.fillHover',
				},
			},
		},
		uploadedItemContent: {
			overflow: 'hidden',
			padding: 4,
			width: '100%',
			borderRadius: '12px',
			border: '1px solid',
			borderColor: 'other.border.regular.default',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			_peerHover: {
				backgroundColor: 'surface.interactive.transparent.regular.hover',
			},
			gap: 3,
			'& > svg': {
				width: '32px',
				height: '32px',
			},
		},
		actionIcon: {},
		stateIcon: {
			marginLeft: 'auto',
		},
		uploadedItemTextContent: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			gap: 2,
		},
	},
	defaultVariants: {
		state: 'accepted',
	},
	variants: {
		state: {
			accepted: {
				uploadedItemContent: {
					color: 'text.regular.subtitle',
					borderColor: 'other.border.regular.default',
					_peerHover: {
						backgroundColor: 'surface.interactive.transparent.regular.hover',
					},
					_peerActive: {
						backgroundColor: 'surface.interactive.transparent.regular.pressed',
					},
				},
				actionIcon: {
					color: 'text.regular.subtitle',
				},
				stateIcon: {
					color: 'icons.inverted.default',
				},
			},
			rejected: {
				uploadedItemContent: {
					color: 'text.regular.danger',
					borderColor: 'icons.regular.danger',
					_peerHover: {
						backgroundColor: 'actions.secondaryDanger.regular.fillHover',
					},
					_peerActive: {
						backgroundColor: 'actions.secondaryDanger.regular.fillPressed',
					},
				},
			},
		},
	},
});
