import { defineThemeContract } from '@pandacss/dev';

export const themeContract = defineThemeContract({
	semanticTokens: {
		fonts: {
			heading: {
				h1: {
					value: '',
				},
			},
		},
		colors: {
			text: {
				regular: {
					default: {
						value: '',
					},
					subtitle: {
						value: '',
					},
					disabled: {
						value: '',
					},
					pressed: {
						value: '',
					},
					danger: {
						value: '',
					},
					success: {
						value: '',
					},
				},
				inverted: {
					default: {
						value: '',
					},
					subtitle: {
						value: '',
					},
					disabled: {
						value: '',
					},
					pressed: {
						value: '',
					},
					danger: {
						value: '',
					},
					success: {
						value: '',
					},
				},
			},
			icons: {
				regular: {
					default: {
						value: '',
					},
					secondary: {
						value: '',
					},
					disabled: {
						value: '',
					},
					pressed: {
						value: '',
					},
					danger: {
						value: '',
					},
				},
				inverted: {
					default: {
						value: '',
					},
					secondary: {
						value: '',
					},
					disabled: {
						value: '',
					},
					pressed: {
						value: '',
					},
					danger: {
						value: '',
					},
				},
				accent: {
					aquamarine: {
						value: '',
					},
					coral: {
						value: '',
					},
					citrine: {
						value: '',
					},
					sand: {
						value: '',
					},
				},
			},
			surface: {
				static: {
					primary: {
						value: '',
					},
					primaryInverted: {
						value: '',
					},
					secondary: {
						value: '',
					},
					tertiary: {
						value: '',
					},
					chat: {
						value: '',
					},
					inverted: {
						value: '',
					},
					invertedException: {
						value: '',
					},
					aquamarine: {
						value: '',
					},
					citrine: {
						value: '',
					},
					coral: {
						value: '',
					},
				},
				interactive: {
					card: {
						primary: {
							value: '',
						},
						secondary: {
							value: '',
						},
						inverted: {
							value: '',
						},
						primaryInverted: {
							value: '',
						},
						primaryAccent: {
							value: '',
						},
						secondaryAccent: {
							value: '',
						},
					},
					transparent: {
						regular: {
							default: {
								value: '',
							},
							hover: {
								value: '',
							},
							pressed: {
								value: '',
							},
							disabled: {
								value: '',
							},
						},
						inverted: {
							default: {
								value: '',
							},
							hover: {
								value: '',
							},
							pressed: {
								value: '',
							},
							disabled: {
								value: '',
							},
						},
					},
					input: {
						regular: {
							hoverAndPressed: {
								value: '',
							},
						},
						inverted: {
							hoverAndPressed: {
								value: '',
							},
						},
					},
					accordion: {
						regular: {
							fillDefault: {
								value: '',
							},
							fillDefaultOpened: {
								value: '',
							},
							fillHover: {
								value: '',
							},
							fillHoverOpened: {
								value: '',
							},
						},
						inverted: {
							fillDefault: {
								value: '',
							},
							fillDefaultOpened: {
								value: '',
							},
							fillHover: {
								value: '',
							},
							fillHoverOpened: {
								value: '',
							},
						},
						sand: {
							fillDefault: {
								value: '',
							},
							fillDefaultOpened: {
								value: '',
							},
							fillHover: {
								value: '',
							},
							fillHoverOpened: {
								value: '',
							},
						},
					},
					skeleton: {
						regular: {
							value: '',
						},
						inverted: {
							value: '',
						},
					},
				},
			},
			actions: {
				primary: {
					regular: {
						fillDefault: {
							value: '',
						},
						textAndIconDefault: {
							value: '',
						},
						fillHover: {
							value: '',
						},
						fillPressed: {
							value: '',
						},
						textAndIconPressed: {
							value: '',
						},
						fillDisabled: {
							value: '',
						},
					},
					inverted: {
						fillDefault: {
							value: '',
						},
						textAndIconDefault: {
							value: '',
						},
						fillHover: {
							value: '',
						},
						fillPressed: {
							value: '',
						},
						textAndIconPressed: {
							value: '',
						},
						fillDisabled: {
							value: '',
						},
					},
				},
				primaryDanger: {
					regular: {
						fillDefault: {
							value: '',
						},
						fillHoverAndPressed: {
							value: '',
						},
						fillDisabled: {
							value: '',
						},
					},
					inverted: {
						fillDefault: {
							value: '',
						},
						fillHoverAndPressed: {
							value: '',
						},
						fillDisabled: {
							value: '',
						},
					},
				},
				secondary: {
					regular: {
						strokeDefault: {
							value: '',
						},
						fillHover: {
							value: '',
						},
						fillPressed: {
							value: '',
						},
						strokeDisabled: {
							value: '',
						},
					},
					inverted: {
						default: {
							value: '',
						},
						fillHover: {
							value: '',
						},
						fillPressed: {
							value: '',
						},
						strokeDisabled: {
							value: '',
						},
					},
				},
				secondaryDanger: {
					regular: {
						strokeDefault: {
							value: '',
						},
						fillHover: {
							value: '',
						},
						fillPressed: {
							value: '',
						},
						strokeDisabled: {
							value: '',
						},
					},
					inverted: {
						strokeDefault: {
							value: '',
						},
						fillHover: {
							value: '',
						},
						fillPressed: {
							value: '',
						},
						strokeDisabled: {
							value: '',
						},
					},
				},
				tertiary: {
					regular: {
						fillDefault: {
							value: '',
						},
						fillHover: {
							value: '',
						},
						fillPressed: {
							value: '',
						},
						fillDisabled: {
							value: '',
						},
					},
					inverted: {
						fillDefault: {
							value: '',
						},
						fillHover: {
							value: '',
						},
						fillPressed: {
							value: '',
						},
						fillDisabled: {
							value: '',
						},
					},
				},
				links: {
					regular: {
						default: {
							value: '',
						},
						hoverAndPressed: {
							value: '',
						},
						visited: {
							value: '',
						},
						disabled: {
							value: '',
						},
					},
					inverted: {
						default: {
							value: '',
						},
						hoverAndPressed: {
							value: '',
						},
						visited: {
							value: '',
						},
					},
				},
				controls: {
					regular: {
						onFillDefault: {
							value: '',
						},
						onFillHoverAndPressed: {
							value: '',
						},
						onStrokeFocus: {
							value: '',
						},
						onFillDisabled: {
							value: '',
						},
						offFillDefault: {
							value: '',
						},
						offFillHoverAndPressed: {
							value: '',
						},
						offFillDisabled: {
							value: '',
						},
						fillThumb: {
							value: '',
						},
					},
					inverted: {
						onFillDefault: {
							value: '',
						},
						onFillHoverAndPressed: {
							value: '',
						},
						onStrokeFocus: {
							value: '',
						},
						onFillDisabled: {
							value: '',
						},
						offFillDefault: {
							value: '',
						},
						offFillHoverAndPressed: {
							value: '',
						},
						offFillDisabled: {
							value: '',
						},
						fillThumb: {
							value: '',
						},
					},
				},
				controlsDanger: {
					regular: {
						onFillDefault: {
							value: '',
						},
						onFillHoverAndPressed: {
							value: '',
						},
						onStrokeFocus: {
							value: '',
						},
						onFillDisabled: {
							value: '',
						},
					},
					inverted: {
						onFillDefault: {
							value: '',
						},
						onFillHoverAndPressed: {
							value: '',
						},
						onStrokeFocus: {
							value: '',
						},
						onFillDisabled: {
							value: '',
						},
					},
				},
				filterTags: {
					regular: {
						fillDefault: {
							value: '',
						},
						strokeHover: {
							value: '',
						},
						fillSelected: {
							value: '',
						},
						fillSelectedHoverAndPressed: {
							value: '',
						},
						fillDisabled: {
							value: '',
						},
						textAndIconDisabled: {
							value: '',
						},
					},
					inverted: {
						fillDefault: {
							value: '',
						},
						strokeHover: {
							value: '',
						},
						fillSelected: {
							value: '',
						},
						fillSelectedHoverAndPressed: {
							value: '',
						},
						fillDisabled: {
							value: '',
						},
						textAndIconDisabled: {
							value: '',
						},
					},
				},
				uploads: {
					fillHover: {
						value: '',
					},
					strokeHover: {
						value: '',
					},
				},
			},
			other: {
				scrim: {
					value: '',
				},
				indicator: {
					regular: {
						default: {
							value: '',
						},
						hover: {
							value: '',
						},
						selected: {
							value: '',
						},
					},
					inverted: {
						hover: {
							value: '',
						},
						selected: {
							value: '',
						},
					},
				},
				border: {
					regular: {
						default: {
							value: '',
						},
						strong: {
							value: '',
						},
						weak: {
							value: '',
						},
						focus: {
							value: '',
						},
						disabled: {
							value: '',
						},
					},
					inverted: {
						default: {
							value: '',
						},
						focus: {
							value: '',
						},
					},
					sand: {
						hairline: {
							value: '',
						},
						weak: {
							value: '',
						},
						strong: {
							value: '',
						},
					},
				},
				pattern: {
					aquamarine: { value: '' },
					citrine: { value: '' },
					coral: { value: '' },
					dark: { value: '' },
					light: { value: '' },
				},
			},
			trafficLights: {
				success: {
					onBackground: {
						value: '',
					},
					default: {
						value: '',
					},
					variant: {
						value: '',
					},
					background: {
						value: '',
					},
				},
				warning: {
					default: {
						value: '',
					},
					background: {
						value: '',
					},
				},
				error: {
					onBackground: {
						value: '',
					},
					default: {
						value: '',
					},
					variant: {
						value: '',
					},
					background: {
						value: '',
					},
					backgroundVariant: {
						value: '',
					},
				},
			},
			signal: {
				background: {
					regular: {
						success: {
							value: '',
						},
						warning: {
							value: '',
						},
						danger: {
							value: '',
						},
						neutral: {
							value: '',
						},
						information: {
							value: '',
						},
					},
					inverted: {
						success: {
							value: '',
						},
						warning: {
							value: '',
						},
						danger: {
							value: '',
						},
						neutral: {
							value: '',
						},
						brand: {
							value: '',
						},
					},
				},
				onBackground: {
					regular: {
						success: {
							value: '',
						},
						warning: {
							value: '',
						},
						danger: {
							value: '',
						},
						neutral: {
							value: '',
						},
						brand: {
							value: '',
						},
					},
					inverted: {
						success: {
							value: '',
						},
						warning: {
							value: '',
						},
						danger: {
							value: '',
						},
						neutral: {
							value: '',
						},
						brand: {
							value: '',
						},
					},
				},
			},
		},
	},
});
