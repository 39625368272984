'use client';

import { Button } from '@/components/core/Button/Button';
import useQueryParams from '@/hooks/useQueryParams';
import useWindowSize from '@/hooks/useWindowSize';
import { CloseIcon } from '@/icons/CloseIcon';
import { SearchIcon } from '@/icons/SearchIcon';
import { css } from '@/styled-system/css';
import { useOutsideClick } from '@/utils/handleOutsideClick';
import { debounce } from 'lodash';
import { FC, useRef, useState } from 'react';
import { Flex, FlexProps, styled } from 'styled-system/jsx';

interface IArticleSearchInputProps extends Omit<FlexProps, 'onChange'> {
	placeholder: string;
	defaultValue: string | number | readonly string[] | undefined;
	onChange?: (text: string | undefined) => void;
}

export const ArticleSearchInput: FC<IArticleSearchInputProps> = ({ placeholder, onChange, defaultValue, ...rest }) => {
	const { query, change } = useQueryParams();
	const { width } = useWindowSize();

	const [isExpanded, setExpanded] = useState(Boolean(defaultValue));

	const inputRef = useRef<HTMLInputElement>(null);
	const wrapperRef = useRef<HTMLInputElement>(null);

	const onSearchChange = () => {
		const value = inputRef?.current?.value || '';

		if (value.length >= 3 || value.length === 0) {
			const sanitizedValue = value.trim().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');

			change({ ...query, search: sanitizedValue });
		}
	};

	const debouncedOnChangeFunction = debounce(onSearchChange, 500); // Debounce with 300ms delay

	const handleSearchButtonClick = () => {
		if (isInputExpanded) {
			if (inputRef.current) {
				inputRef.current.value = '';
			}
			change({ ...query, search: '' });

			return;
		}
		setExpanded((current) => !current);
	};

	useOutsideClick(wrapperRef, () => setExpanded(false));

	const isInputExpanded = (() => {
		if (width >= 1024 || width <= 768) {
			return true;
		}

		return isExpanded || Boolean(inputRef?.current?.value);
	})();

	const isSearchUsed = (inputRef?.current?.value || '').length > 0;

	return (
		<Flex
			ref={wrapperRef}
			position="relative"
			alignItems="center"
			borderLeftRadius={isInputExpanded ? 0 : undefined}
			borderRadius="6px"
			flex={isInputExpanded ? '1' : 'unset'}
			p={isInputExpanded ? ' 4px' : '0px'}
			className={css({
				border: '1px solid',
				borderColor: 'other.border.regular.default',
				'&:focus-within': {
					borderColor: 'other.border.regular.focus',
				},
			})}
			{...rest}
		>
			<styled.input
				ref={inputRef}
				type="text"
				defaultValue={defaultValue}
				className={css({ padding: '0 8px 0 4px', width: '100%' })}
				fontSize={16}
				placeholder={placeholder}
				_focusVisible={{ outline: 0 }}
				style={{ display: isInputExpanded ? 'block' : 'none' }}
				onChange={() => debouncedOnChangeFunction()}
			/>
			<Button
				variant={isInputExpanded ? 'unstyled' : 'secondary'}
				size="unsized"
				onClick={handleSearchButtonClick}
				style={{ borderRadius: '6px', border: 'none', padding: '3px' }}
				leftIcon={
					isSearchUsed ? (
						<CloseIcon
							width={isInputExpanded ? 24 : 32}
							height={isInputExpanded ? 24 : 32}
							color="icons.regular.default"
						/>
					) : (
						<SearchIcon
							width={isInputExpanded ? 24 : 32}
							height={isInputExpanded ? 24 : 32}
							color="icons.regular.default"
						/>
					)
				}
			></Button>
		</Flex>
	);
};
